import React from "react";

import { SinglePreviewProps } from "./types";
import { Box, Typography } from "@mui/material";
import { soilsColor } from "app/enums/soils";

const SinglePreview: React.FunctionComponent<SinglePreviewProps> = ({
  layer,
}) => {
  const typeLayer = layer.find(
    (property) => property.apiName === "type"
  )?.value;
  const thickness =
    Number(layer.find((property) => property.apiName === "thickness")?.value) *
      25 +
    "px";
  return (
    <Box
      sx={{
        backgroundColor: soilsColor.find(
          (soil) => soil.name === String(typeLayer).toLocaleLowerCase()
        )?.color,
        height: thickness,
        padding: "10px",
      }}
    >
      <Typography color="#ffffff"> {typeLayer}</Typography>
    </Box>
  );
};
export default SinglePreview;
