import React, { useEffect } from "react";
import { Button, Divider, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";

import LandingLayout from "layout/Landing/LandingLayout";
import Form from "components/Form/Form";

import {
  FormContent,
  FormActions,
  FormTitle,
} from "components/Form/Form.styled";
import { Controller, useForm } from "react-hook-form";

import Link from "components/Link";

import Logo from "../components/Logo";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "services/webapp/auth/endpoints";
import "../style.scss";

const schema = yup
  .object({
    email: yup.string().required(),
  })
  .required();

const ForgetPasswordPage: React.FunctionComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const { mutate, isSuccess, data } = useMutation(resetPassword);
  const resetPasswordHandler = (data: any) => {
    toast.loading("Mail Sending", {
      autoClose: false,
      toastId: "reset_password",
    });
    mutate({ email: data.email });
  };
  useEffect(() => {
    if (isSuccess && data?.data.type) {
      toast.update("reset_password", {
        render: "The mail has been sent, please check your mail",
        type: "success",
        autoClose: 5000,
        closeOnClick: true,
        isLoading: false,
      });
    }
  }, [data?.data.type, isSuccess]);
  return (
    <LandingLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
        className="fade-in"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.4)",
            alignItems: "center",
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Logo />
          <Form width={400} onSubmit={handleSubmit(resetPasswordHandler)}>
            <FormTitle> Can't log in?</FormTitle>
            <FormContent>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Enter email"
                    helperText={errors.email?.message}
                    error={Boolean(errors.email)}
                    {...field}
                  />
                )}
              />
            </FormContent>
            <FormActions>
              <Button
                onClick={handleSubmit(resetPasswordHandler)}
                type="submit"
                variant="contained"
              >
                Send recovery link
              </Button>
            </FormActions>
            <Divider sx={{ marginTop: "12px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                padding: "12px",
              }}
            >
              <Link to="/sign-in">Return to log in </Link>
            </div>
          </Form>
        </div>
      </div>
    </LandingLayout>
  );
};

export default ForgetPasswordPage;
