import { SubPageContainerProps } from "./types";
import classes from "./style.module.css";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const SubPageContainer: React.FunctionComponent<SubPageContainerProps> = ({
  children,
  title,
  actions,
  onClose,
}) => {
  return (
    <div className={classes.table_container}>
      <div className={classes.table_header}>
        <div className={classes.table_header__title}>{title}</div>
        <div className={classes.table_header__actions}>
          {actions && actions}
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      {children}
    </div>
  );
};

export default SubPageContainer;
