import React, { useEffect, useState } from "react";

import LabModal from "containers/Lab/components/LabModal/LabModal/LabModal";
import Button from "components/Inputs/Button/Button";

import PileRow from "../PileRow";
import { PileModel } from "models/pile";
import { FrictionType, PilesModalProps } from "./types";
import { updatePiles } from "services/webapp/designs/endpoints";
import { useSearchParams } from "react-router-dom";
import useLabStore from "context/lab-store";
import TextField from "components/Inputs/TextField";

const PilesModal: React.FunctionComponent<PilesModalProps> = ({
  handleSelectPile,
  selectedPile,
}) => {
  const { piles, profile, frictions, setLabData, layers, isAnalysis } =
    useLabStore();
  const [layersDeepth, setLayersDeepth] = useState(0);
  let [searchParams] = useSearchParams();
  const addPile = () => {
    setLabData("piles", [
      ...piles,
      {
        cutoff_level: 3,
        diameter: 3,
        length: 3,
        pileName: `Pile ${piles.length + 1}`,
      },
    ]);
    if (piles.length === 0) {
      handleSelectPile(0);
    }
  };

  const savePileProfile = (friction: FrictionType) => {
    setLabData("frictions", { ...frictions, ...friction });
  };

  const savePileRow = (pile: PileModel, index: number) => {
    const pilesCopy = [...piles];
    pilesCopy[index] = pile;
    setLabData("piles", pilesCopy);
  };

  const deletePile = (index: number) => {
    const pilesCopy = [...piles];
    pilesCopy.splice(index, 1);
    setLabData("piles", [...pilesCopy]);
  };

  useEffect(() => {
    updatePiles({
      data: {
        piles: JSON.stringify({ piles, frictions }),
        designId: searchParams.get("d") || "",
      },
    });
  }, [frictions, piles, searchParams]);

  useEffect(() => {
    let totalLengh = 0;
    layers.forEach((layer) => {
      const val = layer.find((l) => l.name === "thickness")?.value;
      totalLengh = totalLengh + Number(val);
    });
    setLayersDeepth(totalLengh);
  }, [layers]);

  return (
    <LabModal
      header={
        <>
          <TextField
            label="Fos Skin"
            size="small"
            name="fos_skin"
            type="number"
            defaultValue={frictions?.fos_skin}
            onChange={(e) =>
              savePileProfile({ fos_skin: Number(e.currentTarget.value) })
            }
            disabled={isAnalysis}
            InputProps={{
              inputProps: {
                step: 0.1,
              },
            }}
          />
          <TextField
            label="Fos Tip"
            size="small"
            name="fos_tip"
            type="number"
            disabled={isAnalysis}
            defaultValue={frictions?.fos_tip}
            onChange={(e) =>
              savePileProfile({ fos_tip: Number(e.currentTarget.value) })
            }
            InputProps={{
              inputProps: {
                step: 0.1,
              },
            }}
          />
        </>
      }
      actions={
        <Button onClick={addPile} variant="contained" disabled={isAnalysis}>
          Add Pile
        </Button>
      }
    >
      {piles.length > 0 &&
        piles.map((pile, index) => (
          <PileRow
            isSelected={selectedPile === index}
            key={index}
            index={index}
            pile={pile}
            savePileRow={(pile) => savePileRow(pile, index)}
            deletePile={deletePile}
            handleSelectPile={handleSelectPile}
            boreHoleLevel={profile?.borehole_level}
            layersDeepth={layersDeepth}
          />
        ))}
    </LabModal>
  );
};

export default PilesModal;
