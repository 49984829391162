import React from "react";
import {
  LabModalActions,
  LabModalContainer,
  LabModalContent,
  LabModalHeader,
} from "./LabModal.styled";

import { LabModalProps } from "./types";

const LabModal: React.FunctionComponent<LabModalProps> = ({
  children,
  header,
  actions,
}) => {
  return (
    <LabModalContainer>
      <LabModalHeader>{header}</LabModalHeader>
      <LabModalContent> {children}</LabModalContent>
      <LabModalActions>{actions}</LabModalActions>
    </LabModalContainer>
  );
};
export default LabModal;
