import React from "react";
import { Button as MuiButton } from "@mui/material";

import { ButtonProps } from "./types";

const Button: React.FunctionComponent<ButtonProps> = ({
  variant,
  onClick,
  bgColor,
  children,
  width,
  padding,
  disabled,
}) => {
  return (
    <MuiButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      sx={{ backgroundColor: bgColor, width: width, padding: padding }}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
