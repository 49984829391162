import * as React from "react";
import LinearProgressMui from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

const LinearProgress: React.FunctionComponent<{ progress: number }> = ({
  progress,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <LinearProgressMui variant="determinate" value={progress} />
        </div>
        <div style={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </div>
      </div>
    </div>
  );
};

export default LinearProgress;
