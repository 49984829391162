import React, { useEffect } from "react";

import useThemeStore from "context/theme-store";
import { getThemeColors } from "styles/theme";
import { ThemeProvider as ThemeProviderMui, createTheme } from "@mui/material";
import { AppTheme } from "app/enums/theme";
import { ThemeProviderProps } from "./types";
import { MockHTML } from "./ThemeProvider.styled";
import { BaseTheme } from "styles/theme/Base.theme";

const ThemeProvider: React.FunctionComponent<ThemeProviderProps> = ({
  children,
}) => {
  const { theme, themeType, setTheme, isThemeSelected } = useThemeStore(
    (state) => state
  );
  // const isBrowserThemeDark = () =>
  //   window.matchMedia("(prefers-color-scheme: dark)").matches;

  useEffect(() => {
    let selectedTheme;
    if (isThemeSelected) {
      selectedTheme = getThemeColors(themeType);
      // } else if (isBrowserThemeDark() && !isThemeSelected) {
      //   selectedTheme = getThemeColors(AppTheme.Dark);
    } else {
      selectedTheme = getThemeColors(AppTheme.Light);
    }

    setTheme({
      customTheme: selectedTheme.customTheme,
      ...BaseTheme,
    });
  }, [themeType, isThemeSelected, setTheme]);

  const customTheme = createTheme({
    customTheme: theme.customTheme,
    ...BaseTheme,
  });

  return (
    <ThemeProviderMui theme={customTheme}>
      <MockHTML>{children}</MockHTML>
    </ThemeProviderMui>
  );
};

export default ThemeProvider;
