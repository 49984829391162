import React, { useCallback, useEffect } from "react";

import useAuthStore from "context/auth-store";
import { decodeToken } from "react-jwt";

import { AuthProviderProps } from "./types";
import useUserStore from "context/user-store";
import { UserModel } from "context/types";

const Auth: React.FunctionComponent<AuthProviderProps> = ({ children }) => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const setUser = useUserStore((state) => state.setUser);

  const authorization = useCallback(() => {
    const user = decodeToken<UserModel>(accessToken || "");
    setUser(user as UserModel);
  }, [accessToken, setUser]);

  useEffect(() => {
    authorization();
  }, [accessToken, authorization]);

  return <>{children}</>;
};

export default Auth;
