//@ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { InputAdornment, useTheme } from "@mui/material";
import { ArrowRight, DeleteOutline } from "@mui/icons-material";
import {
  PileRowContainer,
  PileRowContainerContent,
  PileRowContainerAction,
} from "./PileRow.styled";
import { PileRowProps } from "./types";
import { compareObjects } from "app/utils/object/object";
import useLabStore from "context/lab-store";
import TextField from "components/Inputs/TextField";
import IconButton from "components/Inputs/IconButton";
import classes from "./style.module.css";

const PileRow: React.FunctionComponent<PileRowProps> = ({
  index,
  pile,
  savePileRow,
  deletePile,
  handleSelectPile,
  boreHoleLevel = 0,
  layersDeepth = 0,
  isSelected,
}) => {
  const { customTheme } = useTheme();
  const [rowInputs, setRowInputs] = useState(pile);
  const { setErrors, errors, deleteError, isAnalysis, profile } = useLabStore();
  const [error, setError] = useState({ depth: false, message: "" });

  const savePileAttribute = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name = e.currentTarget.name;
    const value = Number(e.currentTarget.value);
    if (name === "diameter") {
      if (value > 5 || value < 0.1) {
        const indexErr = errors.findIndex(
          (err) => err.index === index && err.name === "diameter"
        );
        if (indexErr === -1) {
          setErrors(index, "diameter", "check diameter", "pile");
        }
      } else {
        const indexErr = errors.findIndex(
          (err) => err.index === index && err.name === "diameter"
        );
        if (indexErr > -1) {
          deleteError(indexErr);
        }
      }
    }
    const depth = layersDeepth;
    if (name === "length") {
      if (value > 200 || value < 1) {
        const indexErr = errors.findIndex(
          (err) => err.index === index && err.name === "length"
        );
        if (indexErr === -1) {
          setErrors(index, "length", "check length", "pile");
        }
      } else {
        const indexErr = errors.findIndex(
          (err) => err.index === index && err.name === "length"
        );
        if (indexErr > -1) {
          deleteError(indexErr);
        }
      }
      if (rowInputs.cutoff_level - value < boreHoleLevel - depth) {
        setErrors(index, "length", "check length", "pile");
      }
    }

    const obj = {
      [name]:
        name === "pileName"
          ? value
          : Number(value) === 0
          ? null
          : Number(value),
    };

    setRowInputs((prev) => {
      return { ...prev, ...obj };
    });
  };

  const checkRow = useCallback(() => {
    const depth = layersDeepth;
    if (rowInputs.cutoff_level - rowInputs.length < boreHoleLevel - depth) {
      setError({
        depth: true,
        message:
          "The length of the pile cannot exceed the total length of the layers",
      });
      return;
    }
    if (rowInputs.cutoff_level > boreHoleLevel) {
      setError({
        depth: true,
        message: "the cuttoff level cannot be greater than borehole",
      });
      return;
    }
    setError({ depth: false, message: "" });
  }, [rowInputs.cutoff_level, rowInputs.length, layersDeepth, boreHoleLevel]);

  useEffect(() => {
    if (!compareObjects(pile, rowInputs)) {
      savePileRow(rowInputs);
    }
    checkRow();
  }, [rowInputs, savePileRow, pile, checkRow]);

  return (
    <PileRowContainer
      onClick={() => handleSelectPile(index)}
      isSelected={isSelected}
    >
      {isAnalysis && (
        <div
          className={classes.isAnalysis}
          onClick={() => handleSelectPile(index)}
        ></div>
      )}
      <PileRowContainerContent>
        <div className={classes.pile_row_arrow}>
          <ArrowRight fontSize="large" />
        </div>
        <TextField
          onFocus={() => handleSelectPile(index)}
          name="pileName"
          label="Pile"
          size="small"
          type="string"
          defaultValue={rowInputs.pileName}
          onChange={savePileAttribute}
          disabled={isAnalysis}
          InputProps={{
            inputProps: {
              style: {
                color: customTheme.font.color,
              },
              step: 0.1,
            },
          }}
        />
        <TextField
          onFocus={() => handleSelectPile(index)}
          name="cutoff_level"
          label="CutOff Level"
          size="small"
          type="number"
          value={rowInputs.cutoff_level}
          error={
            rowInputs.cutoff_level > Number(profile?.borehole_level) ||
            rowInputs.cutoff_level <
              Number(profile?.borehole_level) - layersDeepth
          }
          helperText={
            (rowInputs.cutoff_level > Number(profile?.borehole_level) ||
              rowInputs.cutoff_level <
                Number(profile?.borehole_level) - layersDeepth) &&
            "Pile must stay inside the layer "
          }
          onChange={savePileAttribute}
          disabled={isAnalysis}
          InputProps={{
            inputProps: {
              step: 0.1,
            },
            endAdornment: <InputAdornment position="start">m</InputAdornment>,
          }}
        />
        <TextField
          onFocus={() => handleSelectPile(index)}
          name="diameter"
          label="Diameter"
          size="small"
          type="number"
          error={rowInputs.diameter > 5 || rowInputs.diameter < 0.1}
          disabled={isAnalysis}
          helperText={
            (rowInputs.diameter > 5 || rowInputs.diameter < 0.1) &&
            "less then 5m,bigger then 0.1m"
          }
          defaultValue={rowInputs.diameter}
          onChange={savePileAttribute}
          InputProps={{
            inputProps: {
              step: 0.1,
            },
            endAdornment: <InputAdornment position="start">m</InputAdornment>,
          }}
        />
        <TextField
          onFocus={() => handleSelectPile(index)}
          name="length"
          label="Length"
          size="small"
          type="number"
          error={rowInputs.length > 200 || rowInputs.length < 1 || error.depth}
          disabled={isAnalysis}
          helperText={
            rowInputs.length > 200 || rowInputs.length < 1
              ? "less then 200m, bigger then 1m"
              : error.depth &&
                "The length of the pile cannot exceed the total length of the layers"
          }
          defaultValue={rowInputs.length}
          onChange={savePileAttribute}
          InputProps={{
            inputProps: {
              step: 0.1,
            },
            endAdornment: <InputAdornment position="start">m</InputAdornment>,
          }}
        />
      </PileRowContainerContent>
      {!isAnalysis && (
        <PileRowContainerAction>
          <IconButton onClick={() => deletePile(index)} disabled={isAnalysis}>
            <DeleteOutline />
          </IconButton>
        </PileRowContainerAction>
      )}
    </PileRowContainer>
  );
};

export default PileRow;
