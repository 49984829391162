import styled from "@emotion/styled";

export const LabModalContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  padding: "10px",
  border: "1px #cccccc solid",
  gap: "12px",
  borderRadius: "4px",
});

export const LabModalHeader = styled("div")({
  display: "flex",
  gap: "8px",
  width: "100%",
  padding: "12px 0px",
});

export const LabModalActions = styled("div")({
  display: "flex",
  gap: "8px",
  width: "100%",
});
export const LabModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  overflowY: "scroll",
});
