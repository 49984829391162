export const BaseTheme = {
  palette: {
    primary: {
      main: "#f28c28",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      contrastText: "#47008F",
    },
  },
};
