import React from "react";
import { LayersContainer } from "./components/Layers/Layers.styled";
import Preview from "./components/Preview/Preview";
import LayerModal from "./components/LayersModal";

const LayersStep: React.FunctionComponent = () => {
  return (
    <LayersContainer>
      <LayerModal />
      <Preview />
    </LayersContainer>
  );
};
export default LayersStep;
