export const defaultColors = {
  black: {
    default: "#000",
  },
  white: {
    default: "#fff",
  },
  orange: {
    cadmiumOrange: "#F28C28",
    ochre: "#cc742c",
  },
  yellow: {
    aztecGold: "#c09655",
    metallicBronze: "#a77540",
    darkBronze: "#805a0e",
  },
  gray: {
    default: "#808080",
  },
  brown: {
    sealBrown: "#4F2B14",
  },
};
