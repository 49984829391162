import React, { useEffect } from "react";
import { Button, Divider, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";

import LandingLayout from "layout/Landing/LandingLayout";
import Form from "components/Form/Form";

import {
  FormContent,
  FormActions,
  FormTitle,
} from "components/Form/Form.styled";
import { Controller, useForm } from "react-hook-form";

import Link from "components/Link";
import Logo from "../components/Logo";
import { useMutation } from "@tanstack/react-query";
import { resetUserPassword } from "services/webapp/user/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import "../style.scss";
const schema = yup
  .object({
    password: yup
      .string()
      .test("len", "must be at least 8 characters", (val) => {
        if (!val) return false;
        return val.length > 7;
      })
      .required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const ResetPasswordPage: React.FunctionComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { token } = useParams();
  const { mutate, isSuccess, data } = useMutation(resetUserPassword);

  //const { mutate } = useMutation(resetPassword);
  const updatePasswordHandler = (data: {
    confirmPassword: string;
    password: string;
  }) => {
    toast.loading("The password is being updated, please wait", {
      autoClose: false,
      toastId: "reset_password",
    });
    if (!token) return;
    mutate({ data: { ...data, token } });
  };

  useEffect(() => {
    if (isSuccess && data?.data.type) {
      toast.update("reset_password", {
        render:
          "the password has been successfully changed, you are redirected to the login page",
        type: "success",
        autoClose: 5000,
        closeOnClick: true,
        isLoading: false,
      });
      setTimeout(() => {
        navigate("/sign-in");
      }, 1000);
    }
  }, [data?.data.type, isSuccess, navigate]);
  return (
    <LandingLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
        className="fade-in"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.4)",
            alignItems: "center",
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Logo />
          <Form width={400} onSubmit={handleSubmit(updatePasswordHandler)}>
            <FormTitle> Can't log in?</FormTitle>
            <FormContent>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Password"
                    type="password"
                    helperText={errors.password?.message}
                    error={Boolean(errors.password)}
                    {...field}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Confirm Password"
                    type="password"
                    helperText={errors.confirmPassword?.message}
                    error={Boolean(errors.confirmPassword)}
                    {...field}
                  />
                )}
              />
            </FormContent>
            <FormActions>
              <Button
                onClick={handleSubmit(updatePasswordHandler)}
                type="submit"
                variant="contained"
              >
                Update Password
              </Button>
            </FormActions>
            <Divider sx={{ marginTop: "12px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                padding: "12px",
              }}
            >
              <Link to="/sign-in">Return to log in </Link>
            </div>
          </Form>
        </div>
      </div>
    </LandingLayout>
  );
};

export default ResetPasswordPage;
