import React, { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { TextField } from "@mui/material";
import useLabStore from "context/lab-store";
import { useMutation } from "@tanstack/react-query";
import { updateDesignName } from "services/webapp/designs/endpoints";

const DesignTitle: React.FunctionComponent = () => {
  const { mutate } = useMutation(updateDesignName);
  const { design_name, setLabData } = useLabStore();
  const [searchParams] = useSearchParams();
  const designId = searchParams.get("d") || "";

  const updateDesignNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setLabData("design_name", event.target.value);
    mutate({
      data: {
        designName: event.target.value,
        designId: designId,
      },
    });
  };

  return (
    <TextField
      onChange={updateDesignNameHandler}
      value={design_name}
      size="small"
      InputProps={{ endAdornment: <Edit fontSize="small" /> }}
    />
  );
};
export default DesignTitle;
