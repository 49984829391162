//@ts-nocheck
import React from "react";
import { Box } from "@mui/material";

import { PreviewProps } from "./types";
import { PreviewContainer } from "./Preview.styled";
import useLabStore from "context/lab-store";
import SinglePreview from "./SinglePreview";
import SinglePile from "containers/Lab/steps/Preview/components/Pile/SinglePile";
import Ruler from "containers/Lab/steps/Preview/components/Ruler/Ruler";

const Preview: React.FunctionComponent<PreviewProps> = ({
  selectedPile,
  fullScreen = false,
}) => {
  const profile = useLabStore((state) => state.profile);
  const layers = useLabStore((state) => state.layers);
  const piles = useLabStore((state) => state.piles);
  const selectedPileData =
    selectedPile !== null ? piles[selectedPile] : piles.length >= 0 && piles[0];
  const totalLayersThickness =
    layers
      ?.map((layer) => layer.find((l) => l.apiName === "thickness")?.value)
      ?.reduce((total, next) => Number(total) + Number(next), 0) || 0;

  return (
    <PreviewContainer fullScreen={fullScreen}>
      <Ruler
        height={0}
        max={Number(profile?.borehole_level)}
        min={Number(profile?.borehole_level) - Number(totalLayersThickness)}
      />
      {layers.length > 0 &&
        layers.map((layer) => <SinglePreview opacity layer={layer} />)}
      {selectedPileData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: "0px",
            width: "100%",
          }}
        >
          <SinglePile
            handleAnalysisModal={() => {}}
            pile={selectedPileData}
            pileId={selectedPile || 0}
            pileWidth={selectedPileData.diameter}
            pileHeight={selectedPileData.length}
            elevation={Number(profile?.borehole_level)}
            pileCutoffLevel={selectedPileData.cutoff_level}
            type="single"
          />
        </Box>
      )}
    </PreviewContainer>
  );
};
export default Preview;
