import React from "react";
import { Component } from "containers/types";

import { TypographyProps } from "./types";

const Typography: Component<TypographyProps> = ({
  children,
  weight = "initial",
  size,
}) => {
  return <div style={{ fontWeight: weight, fontSize: size }}>{children}</div>;
};
export default Typography;
