import { TableCellProps } from "./types";
import classes from "./style.module.scss";
import { classNames } from "app/utils/classes";

const TableCell: React.FunctionComponent<TableCellProps> = ({
  children,
  noPadding = false,
}) => {
  return (
    <td
      className={classNames([
        classes.uikit_tableCell,
        noPadding ? classes.uikit_tableCell__noPadding : "",
      ])}
    >
      {children}
    </td>
  );
};
export default TableCell;
