//@ts-nocheck
import React from "react";

import { rangeInteger } from "app/utils/math/range";

import { RulerContainer, RulerContent } from "./Ruler.styled";
import { RulerProps } from "./types";
import UnitRuler from "./UnitRuler";

const Ruler: React.FunctionComponent<RulerProps> = ({ height, max, min }) => {
  return (
    <RulerContainer rulerHeight={height}>
      <RulerContent>
        {rangeInteger(min, max)
          .reverse()
          .map((number) => (
            <UnitRuler number={number} />
          ))}
      </RulerContent>
    </RulerContainer>
  );
};

export default Ruler;
