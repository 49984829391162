export const formattedDateWithGMT = (date: string) => {
  const dateObj = new Date(date);

  const options = {
    year: "2-digit" as const,
    month: "2-digit" as const,
    day: "2-digit" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: true,
    timeZoneName: "short" as const,
  };

  return new Intl.DateTimeFormat("en-GB", options).format(dateObj);
};
