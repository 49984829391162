import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import pdIcon from "app/assets/icon/pile.png";
import Icon from "components/Icon";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ marginBottom: "40px", cursor: "pointer" }}
      onClick={() => navigate("/")}
    >
      <Icon icon={pdIcon} borderRadius={"25%"} width={75} height={75} />
      <Typography
        fontSize={40}
        color="#FF9030"
        fontWeight={"bold"}
        letterSpacing={"-2px"}
        lineHeight={"75px"}
        sx={{
          background: "linear-gradient(to bottom, orange, #f67700)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        Piles Capacity
      </Typography>
    </div>
  );
};
export default Logo;
