export const rangeInteger = (start: number, end: number) => {
  const startValue = Math.floor(start);
  const endValue = Math.floor(end);

  const initialValue =
    startValue % 5 !== 0
      ? startValue % 5 < 0
        ? startValue + (-5 - (startValue % 5))
        : startValue - (startValue % 5)
      : startValue;

  const imitialEndValue =
    endValue % 5 !== 0
      ? endValue % 5 < 0
        ? endValue + (-5 - (endValue % 5))
        : endValue + 5 - (endValue % 5)
      : endValue;

  var ans = [];

  for (let i = initialValue; i <= imitialEndValue; i++) {
    ans.push(i);
  }
  return ans;
};

export const rangeInt = (start: number, end: number) => {
  var ans = [];

  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
};
