const LightThemeSVG = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 300 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="299"
        height="199"
        fill="white"
        stroke="black"
      />
      <rect
        x="10.5"
        y="8.5"
        width="66"
        height="180"
        fill="#FAFAFA"
        stroke="black"
      />
      <rect
        x="88.5"
        y="8.5"
        width="203"
        height="46"
        fill="#FAFAFA"
        stroke="black"
      />
      <rect
        x="88.5"
        y="65.5"
        width="203"
        height="123"
        fill="#FAFAFA"
        stroke="black"
      />
    </svg>
  );
};

export default LightThemeSVG;
