/* eslint-disable react-hooks/exhaustive-deps */

import useToggle from "hooks/Toggle/useToogle";
import useUserStore from "context/user-store";
import { toast } from "react-toastify";
import classes from "./style.module.css";
import { useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateUserEmail } from "services/webapp/user/endpoints";
import Input from "components/UiKit/Input/Input";

const UpdateEmail: React.FunctionComponent = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { user, setUser } = useUserStore();
  const [editModal, toggleEditModal] = useToggle();
  const { mutate, isSuccess } = useMutation(updateUserEmail);
  const submit = () => {
    mutate({ data: { email: ref.current?.value || user.email } });
  };
  useEffect(() => {
    if (isSuccess) {
      toast("Mail has been updated successfully", { type: "success" });
      setUser({ ...user, email: ref.current?.value || "" });
    }
  }, [isSuccess, setUser]);

  return (
    <div className={classes.single_input__container}>
      <div className={classes.single_input}>
        <div className={classes.single_input__title}>{"Email"}</div>
        <div className={classes.single_input__value}>{user.email}</div>

        <button className={classes.btn} onClick={toggleEditModal}>
          Edit
        </button>
      </div>
      {editModal && (
        <div className={classes.edit_modal}>
          <Input
            className={classes.input}
            defaultValue={user.email}
            placeholder={"new value"}
            ref={ref}
          />
          <button className={classes.btn} onClick={submit}>
            Update
          </button>
        </div>
      )}
    </div>
  );
};
export default UpdateEmail;
