import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { DarkTheme } from "styles/theme/Dark.theme";

import { ThemeState } from "./types";
import { AppTheme } from "app/enums/theme";

const useThemeStore = create<ThemeState>()(
  persist(
    (set) => ({
      themeType: AppTheme.Dark,
      theme: { customTheme: DarkTheme },
      isThemeSelected: false,
      setThemeType: (themeType) => set(() => ({ themeType })),
      setThemeSelected: () => set(() => ({ isThemeSelected: true })),
      setTheme: (theme) => set(() => ({ theme })),
    }),
    {
      name: "theme",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useThemeStore;
