import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { DeleteOutline, DragIndicatorSharp } from "@mui/icons-material";

import {
  ModalRowContainer,
  ModalRowContainerTitle,
  ModalRowContainerContent,
  ModalRowContainerContentActions,
} from "./ModalRow.styled";
import { ModalRowProps } from "./types";
import Typography from "components/Typography/Typography";
import { InputAdornment } from "@mui/material";
import {
  validation,
  validation2,
} from "models/validations/SoilLayers.valitdation";
import { SoilPropertiesUnit } from "models/soilProperties";
import { convertToCamelCase } from "app/utils/string";
import useLabStore from "context/lab-store";
import TextField from "components/Inputs/TextField";
import IconButton from "components/Inputs/IconButton";

const ModalRow: React.FunctionComponent<ModalRowProps> = ({
  id,
  index,
  columns,
  save,
  deleteItem,
}) => {
  const { setErrors, errors, deleteError, isAnalysis } = useLabStore();
  const handleSaveRow = (inputValue: { name: string; value: string }) => {
    const res = validation2(inputValue.name, Number(inputValue.value));
    if (res) {
      const indexErr = errors.findIndex(
        (err) => err.index === index && err.name === inputValue.name
      );
      if (indexErr === -1) {
        setErrors(index, inputValue.name, res.helperText, "layers");
      }
    } else {
      const indexError = errors.findIndex(
        (err) => err.index === index && err.name === inputValue.name
      );

      if (indexError > -1) {
        deleteError(indexError);
      }
    }

    const newColumns = [...columns];
    const indexOfRow = newColumns.findIndex(
      (row) => row.apiName === inputValue.name
    );

    if (indexOfRow > -1) {
      newColumns[indexOfRow].value = inputValue.value;
      save(index, newColumns);
      return;
    }
  };

  return (
    <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
      {(provided) => (
        <ModalRowContainer ref={provided.innerRef} {...provided.draggableProps}>
          <ModalRowContainerTitle>
            <Typography>
              {columns.find((input) => input.apiName === "type")?.value}
            </Typography>
          </ModalRowContainerTitle>
          <ModalRowContainerContent>
            {columns.map((row) => {
              if (row.apiName === "type") return false;
              return (
                <TextField
                  key={row.apiName + id}
                  name={row.apiName}
                  size="small"
                  type="number"
                  defaultValue={row.value}
                  label={row.name}
                  disabled={isAnalysis}
                  {...validation(row.apiName, Number(row.value))}
                  onChange={(e) => {
                    handleSaveRow({
                      name: e.currentTarget.name,
                      value: e.currentTarget.value,
                    });
                  }}
                  InputProps={{
                    inputProps: {
                      step: 0.1,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        {
                          SoilPropertiesUnit[
                            convertToCamelCase(
                              row.apiName
                            ) as keyof typeof SoilPropertiesUnit
                          ]
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              );
            })}

            <ModalRowContainerContentActions>
              {!isAnalysis && (
                <>
                  <IconButton
                    onClick={() => deleteItem(index)}
                    disabled={isAnalysis}
                  >
                    <DeleteOutline />
                  </IconButton>

                  <IconButton
                    {...provided.dragHandleProps}
                    disabled={isAnalysis}
                  >
                    <DragIndicatorSharp />
                  </IconButton>
                </>
              )}
            </ModalRowContainerContentActions>
          </ModalRowContainerContent>
        </ModalRowContainer>
      )}
    </Draggable>
  );
};
export default ModalRow;
