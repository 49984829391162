import styled from "@emotion/styled";

export const LabContent = styled("div")({
  display: "flex",
  padding: "12px",
  height: "100%",
  borderRadius: "4px",
  width: "100%",
  boxShadow: "0px 0px 4px 1px#cccccc",
});
