import { AppTheme } from "app/enums/theme";

import { DarkTheme } from "./Dark.theme";
import { LightTheme } from "./Light.theme";

export const getThemeColors = (theme: AppTheme) => {
  switch (theme) {
    case AppTheme.Dark:
      return { customTheme: DarkTheme };
    case AppTheme.Light:
      return { customTheme: LightTheme };
    default:
      return { customTheme: DarkTheme };
  }
};
