import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Delete, MoreVert } from "@mui/icons-material";
import { Menu, MenuItem, IconButton } from "@mui/material";

import useToggle from "hooks/Toggle/useToogle";
import { deleteProject } from "services/webapp/projects/endpoints";

import SharedModal from "../SharedModal/SharedModa";
import { ProjectRowMenuProps } from "./types";

const ProjectRowMenu: React.FunctionComponent<ProjectRowMenuProps> = ({
  project,
}) => {
  const [isShareModalToggle, setShareModalToggle] = useToggle();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const toggleMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const { mutate: deleteProjectService } = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["last_projects"] });
      queryClient.invalidateQueries({ queryKey: ["projects_map"] });
      navigate(`/map`);
    },
  });

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton
        aria-expanded={toggleMenu ? "true" : undefined}
        onClick={handleClick}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu
        sx={{ zIndex: 9999999999 }}
        anchorEl={anchorEl}
        open={toggleMenu}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          sx={{ fontSize: 14, gap: "4px" }}
          onClick={(e) => {
            deleteProjectService({ params: { project_id: project.id } });
            setAnchorEl(null);
          }}
        >
          <Delete fontSize="small" />
          Delete
        </MenuItem>
      </Menu>
      {isShareModalToggle && (
        <SharedModal
          setShareModalToggle={setShareModalToggle}
          project={project}
        />
      )}
    </div>
  );
};

export default ProjectRowMenu;
