import {
  IconButtonProps,
  IconButton as MuiIconButton,
  useTheme,
} from "@mui/material";
const IconButton: React.FunctionComponent<IconButtonProps> = ({
  children,
  ...props
}) => {
  const { customTheme } = useTheme();
  return (
    <MuiIconButton sx={{ color: customTheme.font.color }} {...props}>
      {children}
    </MuiIconButton>
  );
};
export default IconButton;
