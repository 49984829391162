import React from "react";
import {
  TextField as MuiTextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";

const TextField: React.FunctionComponent<TextFieldProps> = ({
  placeholder,
  label,
  size,
  type = "text",
  onChange,
  fullWidth = false,
  value,
  name,
  defaultValue,
  ...props
}) => {
  const { customTheme } = useTheme();
  return (
    <MuiTextField
      sx={{
        "& > div ,input ,span ,label , p": {
          color: customTheme.font.color,
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: customTheme.font.color,
        },
        minWidth: "150px",
      }}
      name={name}
      size={size}
      type={type}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
      label={label}
      {...props}
    />
  );
};

export default TextField;
