//@ts-nocheck
import React from "react";
export const objectWithoutKey = (
  object: { [x: string]: string | React.ReactNode },
  key: string
) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

export const objectWithoutKeys = (
  object: { [x: string]: string | React.ReactNode },
  keys: string[]
) => {
  const arr = [];
  arr.push(object);
  for (let i = 0; i < keys.length; i += 1) {
    const obj = objectWithoutKey(arr[0], keys[i]);
    arr.pop();
    arr.push(obj);
  }
  return arr[0];
};

export const compareObjects = (obj1: object, obj2: object) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Objects have different number of properties
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false; // Property values are not equal
    }
  }

  return true; // All properties are equal
};
