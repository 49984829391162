import React from "react";
import { Box, Typography } from "@mui/material";

import LinearProgress from "components/Progress/LinearProgress";

const PlanDetailPage: React.FunctionComponent = () => {
  return (
    <>
      <Box sx={{ width: "70%" }}>
        <Box>
          <Typography variant="h6">Usage</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography fontSize={16} variant="h6" sx={{ width: "220px" }}>
              license use
            </Typography>
            <LinearProgress progress={30} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography fontSize={16} variant="h6" sx={{ width: "220px" }}>
              users capacity
            </Typography>
            <LinearProgress progress={80} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography fontSize={16} variant="h6" sx={{ width: "220px" }}>
              Projects capacity
            </Typography>
            <LinearProgress progress={25} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PlanDetailPage;
