import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translates/en.language.json";
import tr from "./translates/tr.language.json";
import ru from "./translates/ru.language.json";

const lang = JSON.parse(localStorage.getItem("languageStore") || "{}").state
  ?.language;
i18next.use(initReactI18next).init({
  lng: lang || "en",
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    tr: {
      translation: tr,
    },
    ru: {
      translation: ru,
    },
  },
});

export default i18next;
