import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { LabStoreTypes } from "./types";

const useLabStore = create<LabStoreTypes>()(
  persist(
    (set) => ({
      project_id: "",
      reports: [],
      errors: [],
      layers: [],
      piles: [],
      design_name: "",
      isAnalysis: false,
      frictions: { fos_skin: 3, fos_tip: 3 },
      profile: { borehole_level: 40, gwt_level: 20 },
      setLabData: (dataType, data) => set(() => ({ [dataType]: data })),
      setErrors: (index, name, message, type) =>
        set((state) => ({
          errors: [...state.errors, { name, message, index, type }],
        })),
      deleteError: (index) => {
        return set((state) => {
          const err = [...state.errors];
          err.splice(index, 1);
          return {
            errors: err,
          };
        });
      },
      setReports: (report) =>
        set(() => ({
          reports: report,
        })),
      clear: () =>
        set(() => ({ piles: [], layers: [], reports: [], errors: [] })),
    }),
    {
      name: "labStore",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useLabStore;
