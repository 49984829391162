import Logo from "components/Logo";
import classes from "./style.module.css";

const LoaderScreen = () => {
  return (
    <div className={classes.loaderScreen}>
      <Logo />
      <p className={classes.loaderInfo}>
        The application is starting, please wait
      </p>
      <span className={classes.loader}></span>
    </div>
  );
};
export default LoaderScreen;
