import React from "react";

import SinglePile from "./SinglePile";
import { PilesPreviewContainer } from "./Pile.styled";
import { PilesPreviewProps } from "./types";

const PilesPreview: React.FunctionComponent<PilesPreviewProps> = ({
  piles,
  elevation,
  onClick,
  isAnalysis,
  height,
}) => {
  const colors = ["blue", "green", "red", "yellow"];
  return (
    <PilesPreviewContainer sx={{ height: height * 25 }}>
      {piles.map((pile, index) => (
        <SinglePile
          isAnalysis={isAnalysis}
          backgroundColor={isAnalysis ? colors[index] : undefined}
          pile={pile}
          pileId={index}
          pileWidth={pile.diameter}
          pileHeight={pile.length}
          pileCutoffLevel={pile.cutoff_level}
          elevation={elevation}
          handleAnalysisModal={() => onClick(index)}
        />
      ))}
    </PilesPreviewContainer>
  );
};
export default PilesPreview;
