import React from "react";
import { Button, Divider, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import LandingLayout from "layout/Landing/LandingLayout";
import Form from "components/Form/Form";

import authServices from "services/webapp/auth";

import {
  FormTitle,
  FormContent,
  FormActions,
} from "components/Form/Form.styled";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Link from "components/Link";
import Logo from "../components/Logo";
import useToggle from "hooks/Toggle/useToogle";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";
import TermsOfServiceModal from "../components/TermsOfServiceModal";
import "../style.scss";
const schema = yup
  .object({
    organization: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
    name: yup.string().required(),
  })
  .required();

const SignUp: React.FunctionComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      organization: "",
      name: "",
    },
  });
  const navigate = useNavigate();

  const [privacyModal, togglePrivacyModal] = useToggle();
  const [termsModal, toggleTermsModal] = useToggle();

  const createUser = (form: any) => {
    toast.loading("Credintials Check", { autoClose: false, toastId: "signup" });
    authServices
      .register({ data: form })
      .then((res) => {
        if (res.data.type) {
          toast.update("signup", {
            render: res.data.message,
            type: "success",
            autoClose: 5000,
            isLoading: false,
          });
          navigate("/sign-in");
        } else {
          toast.update("signup", {
            render: res.data.message,
            type: "error",
            autoClose: 5000,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update("signup", {
          render: "Service Not Response",
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      });
  };

  return (
    <LandingLayout>
      <div
        className="fade-in"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
          alignItems: "center",
          minHeight: "100vh",
          height: "fit-content",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Logo />
        <Form width={400} onSubmit={handleSubmit(createUser)}>
          <FormTitle> Sign up for your account</FormTitle>
          <FormContent>
            <div>Organization</div>
            <Controller
              name="organization"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Organization"
                  helperText={errors.organization?.message}
                  error={Boolean(errors.organization)}
                  {...field}
                />
              )}
            />
            <Divider />
            <div>User</div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="name"
                  helperText={errors.name?.message}
                  error={Boolean(errors.name)}
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="email"
                  helperText={errors.email?.message}
                  error={Boolean(errors.email)}
                  {...field}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="password"
                  helperText={errors.password?.message}
                  error={Boolean(errors.password)}
                  {...field}
                  type="password"
                />
              )}
            />
          </FormContent>

          <div style={{ marginTop: "20px", color: "rgb(94, 108, 132)" }}>
            By clicking Register below, you agree to our{" "}
            <Link onClick={() => toggleTermsModal()}>Terms of Service</Link> and{" "}
            <Link onClick={() => togglePrivacyModal()}>Privacy Policy</Link>.
          </div>
          <FormActions>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(createUser)}
            >
              Register
            </Button>
          </FormActions>

          <Divider sx={{ marginTop: "20px" }} />
          <div style={{ padding: "20px", textAlign: "center" }}>
            <Link to="/sign-in">Already have an account? Log In</Link>
          </div>
        </Form>
      </div>
      <PrivacyPolicyModal isOpen={privacyModal} onClose={togglePrivacyModal} />
      <TermsOfServiceModal isOpen={termsModal} onClose={toggleTermsModal} />
    </LandingLayout>
  );
};

export default SignUp;
