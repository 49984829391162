import { ButtonProps } from "@mui/material";
import classes from "./Button.module.css";
import { classNames } from "app/utils/classes";

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  onClick,
  endIcon,
}) => {
  return (
    <button className={classNames([classes.btn])} onClick={onClick}>
      {children}
      {endIcon && endIcon}
    </button>
  );
};
export default Button;
