//@ts-nocheck
import styled from "@emotion/styled";

export const PileRowContainer = styled("div")(
  (props: { isSelected: boolean }) => ({
    position: "relative",
    backgroundColor: props.isSelected ? "rgba(106, 84, 247, 0.2)" : "null",
    display: "flex",
    width: "100%",
    padding: "2px 4px",
    borderRadius: "4px",
  })
);
export const PileRowContainerContent = styled("div")({
  position: "relative",
  display: "flex",
  padding: "10px 0px",
  width: "100%",
  gap: "8px",
});
export const PileRowContainerAction = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});
