import { TableRowProps } from "./types";
import classes from "./style.module.scss";

const TableRow: React.FunctionComponent<TableRowProps> = ({
  children,
  onClick = () => {},
  key,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <tr
      key={key}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={classes.uikit_tableRow}
    >
      {children}
    </tr>
  );
};
export default TableRow;
