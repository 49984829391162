import React, { useState } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { Drawer, Box, IconButton, ButtonGroup, Button } from "@mui/material";
import AnalysisResultModalSingle from "../AnalysisCharts/AnalysisResult";
import { SingleAnalysisResultProps } from "./types";
import useLabStore from "context/lab-store";
import Preview from "containers/Lab/steps/Piles/components/Preview/Preview";
import {
  PileListContainer,
  SingleAnalysisResultContainer,
  SingleAnalysisResultContent,
  SingleAnalysisResultHeader,
} from "./SingleanalysisResult.styled";
import Axis from "../Axis/Axis";

const SingleAnalysisResult: React.FunctionComponent<
  SingleAnalysisResultProps
> = ({ toggleDrawer, setToggleDrawer, selectedPileId }) => {
  const { piles, reports } = useLabStore();
  const [selectedPile, setSelectedPile] = useState(selectedPileId);

  return (
    <Drawer anchor={"right"} open={toggleDrawer} onClose={setToggleDrawer}>
      <SingleAnalysisResultContainer>
        <SingleAnalysisResultHeader>
          <IconButton onClick={setToggleDrawer}>
            <CloseOutlined />
          </IconButton>
        </SingleAnalysisResultHeader>
        <SingleAnalysisResultContent>
          <Axis />
          {selectedPileId !== undefined && (
            <>
              <span
                style={{
                  position: "absolute",
                  top: "-30px",
                  fontSize: "14px",
                  color: "rgba(70,70,70)",
                  fontWeight: "600",
                }}
              >
                Elevation, m
              </span>
              <Preview selectedPile={selectedPile} fullScreen />
            </>
          )}
          <PileListContainer>
            <ButtonGroup orientation="vertical">
              {piles.map((p, index) => (
                <Button
                  variant={selectedPile === index ? "contained" : "outlined"}
                  key={index}
                  onClick={() => setSelectedPile(index)}
                >
                  {p.pileName}
                </Button>
              ))}
            </ButtonGroup>
          </PileListContainer>
          <div style={{ width: "900px", position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                position: "absolute",
                top: "-17px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-23px",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "rgba(70,70,70)",
                }}
              >
                Ultimate Bearing Capacity, kN
              </span>
              <AnalysisResultModalSingle
                analysisResult={reports ? reports[selectedPile || 0] : null}
                pileId={selectedPileId || 0}
              />
            </Box>
          </div>
        </SingleAnalysisResultContent>
      </SingleAnalysisResultContainer>
    </Drawer>
  );
};

export default SingleAnalysisResult;
