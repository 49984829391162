import { TableProps } from "./types";
import classes from "./style.module.scss";
import { Pagination } from "@mui/material";
import NotFound from "components/NotFound/NotFound";
import Loader from "components/Feedback/Loader";

const Table: React.FunctionComponent<TableProps> = ({
  head,
  children,
  pages = 0,
  isEmpty = false,
  notFoundMessage = "not found",
  onPageChange,
  currentPage = 1,
  isLoading,
}) => {
  return (
    <div className={classes.uikit_table_container}>
      <div className={classes.uikit_table_container_content}>
        <table className={classes.uikit_table}>
          <thead className={classes.uikit_table_head}>
            <tr>
              {head.map((headTitle) => (
                <th className={classes.uikit_table_head_title}>{headTitle}</th>
              ))}
            </tr>
          </thead>
          <tbody>{!isLoading && !isEmpty && children}</tbody>
        </table>
        {!isLoading && isEmpty && <NotFound text={notFoundMessage} />}
        {isLoading && <Loader />}
      </div>
      {pages !== 0 && (
        <div className={classes.uikit_table_footer}>
          <Pagination
            count={pages}
            shape="rounded"
            onChange={onPageChange}
            page={currentPage}
          />
        </div>
      )}
    </div>
  );
};
export default Table;
