import { LayoutProps } from "./types";
import BackgroundImage from "app/assets/img/background.jpg";
const LandingLayout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowY: "scroll",
      }}
    >
      {/* <Header /> */}
      {children}
    </div>
  );
};

export default LandingLayout;
