import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle, Notifications, Settings } from "@mui/icons-material";
// import { FormControlLabel, IconButton, Switch } from "@mui/material";
// import useModeStore from "context/mode-store";
import Logo from "./Logo";
import classes from "./Header.module.scss";
import SearchArea from "./SearchArea";
import useAuthStore from "context/auth-store";
import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "services/webapp/notifications/endpoints";

const MapHeader: React.FunctionComponent = () => {
  // const { mode, setMode } = useModeStore((state) => state);
  const navigate = useNavigate();
  const { clearAccessToken } = useAuthStore((state) => state);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    clearAccessToken();
    navigate("/");
  };
  const { data } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
  });
  const notificationCount = (
    data?.data.results.filter((n) => n.is_active) || []
  ).length;
  return (
    <div className={classes.header}>
      <Logo />
      <SearchArea />

      <IconButton onClick={() => navigate("/settings")} color="primary">
        <Settings />
      </IconButton>

      <IconButton onClick={() => navigate("/notifications")} color="primary">
        <Badge color="success" badgeContent={notificationCount}>
          <Notifications />
        </Badge>
      </IconButton>

      <IconButton onClick={openAccountMenu} color="primary">
        <AccountCircle />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate("/account");
          }}
        >
          Account
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
export default MapHeader;
