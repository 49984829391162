import classes from "./style.module.css";
import PricingItem from "./PricingItem";

const PricingList = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <div className={classes.pricing_title}>
        Piles Capacity Features and Pricing
      </div>
      <div className={classes.pricing_list_items}>
        <PricingItem
          features={[
            {
              value: 1,
              label: "User",
            },
            {
              value: 1,
              label: "Project",
            },
            {
              value: 1,
              label: "Design",
            },
          ]}
          title="Basic"
          color={classes.green}
        />
        <PricingItem
          features={[
            {
              value: 3,
              label: "User",
            },
            {
              value: 10,
              label: "Project",
            },
            {
              value: 100,
              label: "Design",
            },
          ]}
          title="Pro"
          color={classes.orange}
        />
        <PricingItem
          features={[
            {
              value: 10,
              label: "Users",
            },
            {
              value: 25,
              label: "Projects",
            },
            {
              value: 500,
              label: "Designs",
            },
          ]}
          title="Premium"
          color={classes.blue}
        />
        <PricingItem
          features={[
            {
              value: 100,
              label: "Users",
            },
            {
              value: 100,
              label: "Projecs",
            },
            {
              value: 100,
              label: "Designs",
            },
          ]}
          title="Enterprise"
          color={classes.yellow}
        />
      </div>
    </div>
  );
};
export default PricingList;
