import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import LogoImg from "app/assets/icon/pile.png";
const Logo: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/")}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        cursor: "pointer",
      }}
    >
      <img
        src={LogoImg}
        width={36}
        style={{ borderRadius: "4px" }}
        alt="logo"
      />
      <Typography
        sx={{
          color: "#f28c28",
          textShadow: "1px 1px rgb(117, 76, 0)",
          fontSize: "48px",
          fontWeight: "bold",
        }}
      >
        Piles Capacity
      </Typography>
    </div>
  );
};

export default Logo;
