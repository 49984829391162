import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Step, StepLabel, Stepper, TextField } from "@mui/material";

import { classNames } from "app/utils/classes";
import { FormTitle } from "components/Form/Form.styled";
import LandingLayout from "layout/Landing/LandingLayout";

import classes from "./style.module.css";

type FormInput = {
  name: string;
  organization: string;
  email: string;
  password: string;
};
const SignUpMultiple = () => {
  const [selectedStep, setSelectedStep] = useState(0);
  const { control, handleSubmit } = useForm<FormInput>();

  const createAccount: SubmitHandler<FormInput> = (data) => {
    console.log(data);
  };
  return (
    <LandingLayout>
      <div className={classes.page_container}>
        <div className={classes.form_container}>
          <FormTitle> Sign up for your account</FormTitle>
          <Stepper
            activeStep={selectedStep}
            style={{ width: "100%", marginTop: "20px" }}
          >
            {["Account", "Package", "Payment"].map((label, index) => {
              return (
                <Step key={label} completed={selectedStep === index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form
            onSubmit={handleSubmit(createAccount)}
            className={classes.form_content}
          >
            <div>
              {selectedStep === 0 && (
                <div className={classes.account_form}>
                  <div>Organization</div>
                  <Controller
                    name="organization"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        placeholder="Organization"
                      />
                    )}
                  />
                  <div>User</div>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} size="small" placeholder="Name" />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} size="small" placeholder="email" />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        placeholder="Password"
                      />
                    )}
                  />
                </div>
              )}
              {selectedStep === 1 && (
                <div className={classes.account_form}>
                  <button>
                    <div className={classNames([classes.green, classes.item])}>
                      <span> Basic - 10$</span>
                      <button type="button">Detail</button>
                    </div>
                  </button>
                  <button>
                    <div className={classNames([classes.orange, classes.item])}>
                      <span> Pro - 10$</span>
                      <button type="button">Detail</button>
                    </div>
                  </button>
                  <button>
                    <div className={classNames([classes.blue, classes.item])}>
                      <span>Premium - 10$</span>
                      <button type="button">Detail</button>
                    </div>
                  </button>
                </div>
              )}
              {selectedStep === 2 && (
                <div className={classes.payment_form}>
                  <TextField placeholder="Card Number" size="small" />
                  <TextField placeholder={"MM/YYYY"} size="small" />
                  <TextField placeholder={"Security Code"} size="small" />
                </div>
              )}
            </div>

            <div className={classes.form_action}>
              <button
                disabled={selectedStep === 0}
                className={classes.btn}
                onClick={() => setSelectedStep(selectedStep + -1)}
              >
                Prev
              </button>
              {selectedStep !== 2 ? (
                <button
                  className={classes.btn}
                  onClick={() => setSelectedStep(selectedStep + 1)}
                >
                  Next
                </button>
              ) : (
                <button className={classes.btn} type="submit">
                  Create Account
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </LandingLayout>
  );
};
export default SignUpMultiple;
