import LandingPage from "containers/Landing/LandingPage";
//import SignUpPage from "containers/Membership/SignUp";
import AccountPage from "containers/Account";
import LabPage from "containers/Lab/Lab";
import SettingsPage from "containers/Settings";
import NotificationsPage from "containers/Notifications/NotificationsPage";
import UsersPage from "containers/Account/Users/UsersPage";
import ProjectPage from "containers/Project";
import PaymentPage from "containers/Account/Payment";
import PlanDetailPage from "containers/Account/Plan";

import MapPage from "containers/Map";
import ProjectsPage from "containers/Projects";
import CreateProjectsPage from "containers/CreateProject";
import SignUpMultiple from "containers/Membership/SignUp/SignUpMultiple";
import {
  SignInPage,
  SignUpPage,
  ResetPasswordPage,
  ForgetPasswordPage,
} from "containers/Membership";
import NotFoundPage from "containers/NotFound";

const routes = [
  { path: "/", Page: LandingPage, security: "public", layout: false },
  { path: "/sign-in", Page: SignInPage, security: "public", layout: false },
  { path: "/sign-up", Page: SignUpPage, security: "public", layout: false },
  {
    path: "/sign-up-multiple",
    Page: SignUpMultiple,
    security: "public",
    layout: false,
  },
  {
    path: "/forget_password",
    Page: ForgetPasswordPage,
    security: "public",
    layout: false,
  },
  {
    path: "/reset_password/:token",
    Page: ResetPasswordPage,
    security: "public",
    layout: false,
  },
  { path: "/map", Page: MapPage, security: "protected", layout: true },
  {
    path: "/project/create",
    Page: CreateProjectsPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/projects",
    Page: ProjectsPage,
    security: "protected",
    layout: true,
  },
  { path: "/account", Page: AccountPage, security: "protected", layout: true },
  { path: "/project", Page: ProjectPage, security: "protected", layout: true },
  { path: "/project/recycle-bin", Page: ProjectPage, security: "protected" },
  {
    path: "/notifications",
    Page: NotificationsPage,
    security: "protected",
    layout: true,
  },
  { path: "/lab", Page: LabPage, security: "protected", layout: true },
  {
    path: "/settings",
    Page: SettingsPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/organization/users",
    Page: UsersPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/organization/payment",
    Page: PaymentPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/organization/plan-details",
    Page: PlanDetailPage,
    security: "protected",
    layout: true,
  },
  {
    path: "/*",
    Page: NotFoundPage,
    security: "public",
    layout: false,
  },
];

export default routes;
