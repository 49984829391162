import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";

import designsServices from "services/webapp/designs";

import { CreateDesignProps } from "./types";
import useDesignsStore from "context/designs-store";

const CreateDesignModal: React.FunctionComponent<CreateDesignProps> = ({
  projectId,
  refetch,
  toggleModal,
}) => {
  const { designCount } = useDesignsStore();
  const [designName, setDesignName] = useState<string>(
    `Design ${designCount + 1}`
  );
  const createDesign = async () => {
    const res = await designsServices.createDesign({
      data: { designName, projectId: projectId },
    });

    if (res.data.type) {
      // navigate(`/lab?d=${res.data.data.design.id}`);
      refetch();
      toast("Successful");
    }
    toggleModal();
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        toggleModal();
      }}
      style={{
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        alignItems: "center",
        borderRadius: "4px",
        zIndex: 10000,
        padding: "12px 24px",
        backgroundColor: "rgba(0,0,0,0.4)",
        width: "100%",
        height: "100vh",
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          backgroundColor: "#ffffff",
          padding: "20px",
          gap: "12px",
          display: "flex",
          flexDirection: "column",
          width: "400px",
          borderRadius: "4px",
        }}
      >
        <TextField
          value={designName}
          onChange={(e) => setDesignName(e.currentTarget.value)}
          sx={{ width: "100%" }}
        />
        <Button variant="contained" onClick={() => createDesign()}>
          Create Design
        </Button>
      </div>
    </div>
  );
};

export default CreateDesignModal;
