import { Request } from "services/_base";
import { IService, Service } from "services/types";

import {
  GET_DESIGNS_URL,
  POST_CREATE_DESIGN_URL,
  PUT_DELETE_DESIGN_URL,
} from "./constants";
import {
  GetDesignsServiceResponse,
  GetDesignsServiceVariables,
  CreateDesignServiceVariables,
  CreateDesignsServiceResponse,
  DeleteDesignServiceVariables,
  DeleteDesignsServiceResponse,
  UpdateLayersResponse,
  UpdateLayersVariables,
  UpdatePilesVariables,
  UpdatePilesResponse,
  GetDesignVariables,
  GetDesignResponse,
  DuplicaDesignResponse,
  DuplicateDesignVariables,
  GetDesignsVariables,
  GetDesignsResponse,
  UpdateDesignVariables,
  UpdateDesignResponse,
  PutDesignsNameVariables,
  PutDesignsNameResponse,
} from "./types";

export const getDesignsWithProjectId: IService<
  GetDesignsServiceVariables,
  GetDesignsServiceResponse
> = ({ data }) => {
  return Request.get(GET_DESIGNS_URL, data);
};
export const createDesign: Service<
  CreateDesignServiceVariables,
  CreateDesignsServiceResponse
> = ({ data }) => {
  return Request.post(POST_CREATE_DESIGN_URL, data);
};

export const deleteDesign: Service<
  DeleteDesignServiceVariables,
  DeleteDesignsServiceResponse
> = ({ data }) => {
  return Request.delete(PUT_DELETE_DESIGN_URL, data);
};

export const updateLayers: Service<
  UpdateLayersVariables,
  UpdateLayersResponse
> = ({ data }) => {
  return Request.put("designs/layers", data, {});
};
export const updateDesign: Service<
  UpdateDesignVariables,
  UpdateDesignResponse
> = ({ data, params }) => {
  return Request.put(`designs/${params.id}`, data, {});
};
export const updatePiles: Service<
  UpdatePilesVariables,
  UpdatePilesResponse
> = ({ data }) => {
  return Request.put("designs/piles", data, {});
};

export const getDesign: Service<GetDesignVariables, GetDesignResponse> = ({
  params,
}) => {
  return Request.get(`designs/${params.designId}`, {});
};

export const duplicateDesign: Service<
  DuplicateDesignVariables,
  DuplicaDesignResponse
> = ({ params }) => {
  return Request.post(`designs/${params.designId}/duplicate`, {}, {});
};

export const getDesigns: Service<
  GetDesignsVariables,
  GetDesignsResponse
> = () => {
  return Request.get(`designs`, {});
};

export const updateDesignName: Service<
  PutDesignsNameVariables,
  PutDesignsNameResponse
> = ({ data }) => {
  return Request.put(
    `/designs/${data.designId}/name`,
    { designName: data.designName },
    {}
  );
};
