import { useEffect, useMemo } from "react";
import { MultipleAllowableChartProps } from "./types";
import useLabStore from "context/lab-store";
import useChart from "hooks/Chart/useChart";

const MultipleAllowableChart: React.FunctionComponent<
  MultipleAllowableChartProps
> = ({ analysisResult, piles }) => {
  const { profile } = useLabStore();
  const findHeight = () => {
    let max = 0;
    piles.forEach((p) => {
      const height =
        Math.abs(Number(profile?.borehole_level)) -
        Math.abs(p.cutoff_level) +
        p.length;
      if (height > max) {
        max = height;
      }
    });
    return max;
  };
  const maxValue = findHeight();
  const minValue = Number(profile?.borehole_level) - findHeight();
  const { chart, setOption } = useChart({ height: maxValue * 25 + 22 + "px" });
  const colors = useMemo(() => ["blue", "green", "red", "yellow"], []);

  useEffect(() => {
    if (analysisResult) {
      setOption({
        xAxis: {
          position: "top",
          type: "value",
          axisLine: {
            show: false, // Hide full Line
          },
          xisTick: {
            show: false, // Hide Ticks,
          },
          splitLine: {
            lineStyle: {
              color: "#aaaaaa",
            },
          },
          show: true,
        },
        yAxis: {
          verticalAlign: "end",
          show: false,
          type: "value",

          max: profile?.borehole_level,
          min: minValue,
        },
        legend: {
          show: false,
          data: piles.map((p) => p.pileName),
          top: 0,
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {},
          // },
        },
        grid: {
          left: "0",
          right: "20",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     start: 0,
        //     end: 100,
        //     yAxisIndex: [0, 1],
        //   },
        // ],
        series: analysisResult.map((analysis, i) => {
          return {
            data:
              analysis.allowable_bearing_capacities.map((item, index) => [
                item,
                piles[i].cutoff_level - analysis.depths[index],
              ]) || [],
            name: piles[i].pileName,
            showSymbol: false,
            lineStyle: { color: colors[i], width: 2 },
            type: "line",
            smooth: true,
          };
        }),
      });
    }
  }, [
    setOption,
    piles,
    analysisResult,
    profile?.borehole_level,
    colors,
    minValue,
  ]);

  return <div style={{ width: "100%" }}>{chart}</div>;
};

export default MultipleAllowableChart;
