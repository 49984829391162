import React from "react";
import { Typography } from "@mui/material";

import Modal from "components/Modal";

const PrivacyPolicyModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  return (
    <Modal title="Privacy Policy" isOpen={isOpen} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
        }}
      >
        <Typography>
          SIMPLY PUT, WE WILL MAKE SURE TO KEEP YOUR DATA PRIVATE AND SECURE.
        </Typography>
        <Typography>
          It is our policy to never explicitly share any information about you
          or your activities to third parties. Below are the specific details of
          how your personal information is handled by hetGE.
        </Typography>
        <Typography>
          The best way for an app to keep your private data private is to avoid
          handling it altogether.
        </Typography>
        <Typography>
          We strive to do this whenever possible. Currently none of our apps
          access your Address Book, Calendar, Photos or Location. If this ever
          changes we will update this document to reflect what we access and
          why. It is our philosophy that the only reason an app should use your
          data is to make your life easier.
        </Typography>
        <Typography>
          This website uses Google Analytics. Any data gathered is subject to
          the Google Privacy Policy. We can only view the analytics data in
          aggregate, and never see any personally-identifiable information.
        </Typography>
        <Typography>
          If you sign up for our newsletter, we will store your email address in
          a database so we can send you updates. Your email address will remain
          completely private; we will never share or sell it to anyone. If you
          choose to unsubscribe your email address will be removed from the
          database completely.
        </Typography>
      </div>
    </Modal>
  );
};
export default PrivacyPolicyModal;
