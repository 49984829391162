import MapContext from "bootstrap/Map/Context";
import Map from "bootstrap/Map/Map";
import { useContext, useEffect } from "react";
import { ProjectModel } from "services/webapp/models";
import "./Landmark.scss";
import { renderAnnotationCallout } from "./helper";
import { useNavigate } from "react-router-dom";

const MapKit: React.FunctionComponent<{ projects: Array<ProjectModel> }> = ({
  projects,
}) => {
  const { map } = useContext(MapContext);
  const navigate = useNavigate();

  const createProjectMarker = (project: ProjectModel) => {
    var MarkerAnnotation = mapkit?.MarkerAnnotation;
    var loc = new mapkit.Coordinate(
      Number(project.location.lat),
      Number(project.location.lng)
    );

    var marker = new MarkerAnnotation(loc, {
      callout: renderAnnotationCallout(project, navigate),
      color: "#FF8C00",
      title: project.name,
    });
    return marker;
  };

  useEffect(() => {
    const annotations = projects.map((p) => createProjectMarker(p));
    if (map) {
      map.showItems(annotations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, projects]);

  return <Map />;
};
export default MapKit;
