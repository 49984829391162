import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { AuthState } from "./types";

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      accessToken: null,
      setAccessToken: (token) => set(() => ({ accessToken: token })),
      clearAccessToken: () => set(() => ({ accessToken: null })),
    }),
    {
      name: "authStore",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;
