import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import Button from "components/Inputs/Button/Button";

const NotFoundPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Typography fontSize={75} fontWeight={900}>
        404
      </Typography>
      <Typography fontSize={20}> Page Not Found</Typography>
      <Button onClick={() => navigate("/")}> Go Home</Button>
    </div>
  );
};

export default NotFoundPage;
