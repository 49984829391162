//@ts-nocheck
import styled from "@emotion/styled";
import { Box } from "@mui/material";
export const PileContainer = styled("div")(
  (props: {
    pileWidth: number;
    pileHeight: number;
    pileCutoffLevel: string;
    backgroundColor: string | undefined;
  }) => ({
    minWidth: props.pileWidth,
    maxWidth: props.pileWidth,
    height: props.pileHeight,
    marginTop: props.pileCutoffLevel,
    backgroundColor: props.backgroundColor || "rgba(106, 84, 247, 0.5)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    cursor: "pointer",
  })
);

export const PileCircleTop = styled("div")(
  (props: { backgroundColor: string | undefined }) => ({
    width: "100%",
    height: "25px",
    transform: "skew(0deg)",
    backgroundColor: props.backgroundColor || "rgba(106, 84, 247)",
    borderRadius: "50%",
    position: "absolute",
    top: "-10px",
  })
);

export const PileCircleBottom = styled("div")(
  (props: { backgroundColor: string | undefined }) => ({
    width: "100%",
    height: "25px",
    transform: "skew(0deg)",
    backgroundColor: props.backgroundColor || "rgba(106, 84, 247)",
    borderRadius: "50%",
    position: "absolute",
    bottom: "-10px",
  })
);
export const PileBody = styled("div")({
  height: "100%",
});

export const PilesPreviewContainer = styled(Box)({
  display: "flex",
  padding: "0px 20px",
  overflowX: "scroll",
  overflowY: "hidden",
  width: "100%",
  gap: "20px",
  zIndex: 1,
});
