import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Table } from "components/Table";

import DesignTableRow from "containers/Project/components/DesignTableRow/DesignTableRow";
import CreateDesignModal from "./components/CreateDesignModal";
import useToggle from "hooks/Toggle/useToogle";
import Button from "components/UiKit/Button/Button";
import useDesignsStore from "context/designs-store";
import SubPageContainer from "components/SubPageContainer/SubPageContainer";
import { useTranslation } from "react-i18next";
import { getDesignsWithProjectId } from "services/webapp/designs/endpoints";
import { useQuery } from "@tanstack/react-query";

const ProjectPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [queryParameters] = useSearchParams();
  const page = queryParameters.get("page") || "1";
  const projectId = queryParameters.get("p");
  const [pages, setPages] = useState(1);

  const [isOpencreateDesignModal, toggleCreateDesignModal] = useToggle();
  const { setDesignCount } = useDesignsStore();

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["project", projectId, page],
    queryFn: () =>
      getDesignsWithProjectId({
        data: {
          projectId: projectId || "",
          page: page,
        },
      }),
  });

  useEffect(() => {
    if (data?.data.results?.designs) {
      setDesignCount(data.data.metadata?.count);
      setPages(data.data.metadata.pages);
    }
  }, [data, setDesignCount]);

  const navigate = useNavigate();

  const designs = data?.data.results?.designs;
  const designsCount = data?.data.metadata?.count;
  const pageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    navigate(`?p=${projectId}&page=${page}`);
  };
  return (
    <>
      <SubPageContainer
        title={`Designs (${designsCount || 0})`}
        onClose={() => navigate("/projects?page=1")}
        actions={
          <Button onClick={toggleCreateDesignModal} variant="text">
            {t("dashboard.quickstart.createDesign")}
          </Button>
        }
      >
        <Table
          head={["Design name", "Created on", "Status", "", ""]}
          pages={pages}
          notFoundMessage={t("dashboard.noDesign")}
          isEmpty={!designs}
          onPageChange={pageChange}
          currentPage={Number(page)}
          isLoading={isLoading}
        >
          {designs?.map((row, index) => (
            <DesignTableRow
              key={row.id}
              design={row}
              refetch={() => refetch()}
            />
          ))}
        </Table>
      </SubPageContainer>

      {projectId && isOpencreateDesignModal && (
        <CreateDesignModal
          projectId={projectId}
          refetch={() => refetch()}
          toggleModal={toggleCreateDesignModal}
        />
      )}
    </>
  );
};

export default ProjectPage;
