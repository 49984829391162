import React from "react";

import { DashboardLayoutProps } from "../Landing/types";
import {
  LabLayoutContainer,
  LabLayoutContent,
} from "./components/LabLayout.styled";
import LabLayoutHeader from "./components/Header/Header";

const LabLayout: React.FunctionComponent<DashboardLayoutProps> = ({
  children,
}) => {
  return (
    <LabLayoutContainer>
      <LabLayoutHeader />
      <LabLayoutContent>{children}</LabLayoutContent>
    </LabLayoutContainer>
  );
};

export default LabLayout;
