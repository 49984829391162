import React from "react";
import { Alert } from "@mui/material";
import { NotFoundProps } from "./types";

const NotFound: React.FunctionComponent<NotFoundProps> = ({
  text = " Not Found",
}) => {
  return (
    <div style={{ marginTop: "20px", width: "100%" }}>
      <Alert variant="standard" severity="error">
        {text}
      </Alert>
    </div>
  );
};
export default NotFound;
