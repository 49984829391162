import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  shareProject,
  undoShareProject,
} from "services/webapp/projects/endpoints";
import Modal from "components/Modal";
import { Table, TableCell, TableRow } from "components/Table";

import { getProjectsUser } from "services/webapp/projects/endpoints";
import { ShareModalProps } from "../ProjectTableRow/types";
import classes from "./style.module.css";
import useUserStore from "context/user-store";

const SharedModal: React.FunctionComponent<ShareModalProps> = ({
  project,
  setShareModalToggle,
}) => {
  const userID = useUserStore((state) => state.user.id);
  const queryClient = useQueryClient();
  const { mutate: share } = useMutation({
    mutationFn: shareProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["project-users"] });
    },
  });
  const { mutate: undoShare } = useMutation({
    mutationFn: undoShareProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["project-users"] });
    },
  });
  const { data } = useQuery({
    queryKey: ["project-users"],
    queryFn: () => getProjectsUser({ params: { project_id: project.id } }),
  });

  const shareProjectHandle = (user_id: string) => {
    share({
      data: {
        project_id: project.id,
        selected_user_id: user_id,
      },
    });
  };
  const undoShareProjectHandle = (user_id: string) => {
    undoShare({
      data: {
        project_id: project.id,
        selected_user_id: user_id,
      },
    });
  };
  return (
    <Modal
      isOpen
      title={"Share Project"}
      onClose={(e) => {
        e.stopPropagation();
        setShareModalToggle();
      }}
    >
      <div>
        <Table head={["email", ""]}>
          {data?.data.data.users?.map((d) => (
            <TableRow key={d.id}>
              <TableCell>{d.email}</TableCell>
              {project.user_id === userID && (
                <TableCell>
                  {d.projectShared ? (
                    <button
                      className={classes.btn}
                      onClick={() => undoShareProjectHandle(d.id)}
                    >
                      Undo Share
                    </button>
                  ) : (
                    <button
                      className={classes.btn}
                      onClick={() => shareProjectHandle(d.id)}
                    >
                      Share
                    </button>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </div>
    </Modal>
  );
};
export default SharedModal;
