import styled from "@emotion/styled";

export const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderRadius: "4px",
  backgroundColor: "#fff",
  gap: "8px",
});

export const FormTitle = styled("div")({
  fontWeight: "bold",
  color: "#5e6c84",
  padding: "10px",
  fontSize: "20px",
});

export const FormContent = styled("div")({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "12px",
});
export const FormActions = styled("div")({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "12px",
});
