import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";

import { CreateProjectModalProps, ICreateProjectInputs } from "./types";
import { creataProject } from "services/webapp/projects/endpoints";

const CreatePrtojectModal: React.FunctionComponent<CreateProjectModalProps> = ({
  open,
  setOpen,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lat: "",
      lon: "",
      projectname: "",
    },
  });
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: creataProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["last_projects"] });
      queryClient.invalidateQueries({ queryKey: ["projects_map"] });
    },
  });

  //   setLocation({
  //     coordinate: {
  //       latitude: parseFloat(lat),
  //       longitude: parseFloat(lng),
  //     },
  //     name: projectName,
  //     country: "",
  //   });
  const createProject: SubmitHandler<ICreateProjectInputs> = (values) => {
    const location = { lat: values.lat, lng: values.lon, displayName: "" };
    mutate({
      data: {
        projectName: values.projectname,
        location: JSON.stringify(location),
      },
    });
  };
  return (
    <Dialog open={open} onClose={() => setOpen()}>
      <DialogTitle>
        Provide Name and Coordinates in <b>Decimal Degrees</b> format
      </DialogTitle>
      <form onSubmit={handleSubmit(createProject)}>
        <DialogContent>
          <Controller
            name="projectname"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                label="Project Name"
                type="text"
                fullWidth
                variant="outlined"
                error={errors.projectname && true}
                helperText={
                  errors.projectname &&
                  "Project name is required. Please enter a name for the project."
                }
              />
            )}
          />
          <Controller
            name="lat"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Latitude"
                type="number"
                fullWidth
                variant="outlined"
                error={errors.lat && true}
                helperText={
                  errors.lat &&
                  "Latitude is required. Please provide a valid longitude value"
                }
              />
            )}
          />
          <Controller
            name="lon"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Longitude"
                type="number"
                fullWidth
                variant="outlined"
                error={errors.lon && true}
                helperText={
                  errors.lon &&
                  "Longitude is required. Please provide a valid longitude value "
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen()} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            <b>Create Project</b>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreatePrtojectModal;
