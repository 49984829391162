import { Request } from "services/_base";
import { IService, Service } from "services/types";

import {
  GET_PROJECTS_URL,
  CREATE_PROJECT_URL,
  DELETE_PROJECT_URL,
  ARCHIVE_PROJECT_URL,
  GET_LAST_PROJECTS,
  GET_PROJECT_USERS_URL,
  POST_UNDO_SHARE_PROJECT_URL,
  POST_SHARE_PROJECT_URL,
} from "./constants";
import {
  GetProjectServiceResponse,
  GetProjectsServiceVariables,
  CreateProjectServiceVariables,
  DeleteProjectServiceVariables,
  DeleteProjectServiceResponse,
  ArchiveProjectServiceVariables,
  ArchiveProjectServiceResponse,
  CreatetProjectServiceResponse,
  GetProjectUserServiceResponse,
  GetProjectUserServiceVariables,
  ShareProjectServiceVariables,
  ShareProjectServiceResponse,
  UndoShareProjectServiceVariables,
  UndoShareProjectServiceResponse,
} from "./types";

export const getProjects: IService<
  GetProjectsServiceVariables,
  GetProjectServiceResponse
> = (params) => {
  return Request.get(GET_PROJECTS_URL, params);
};

export const creataProject: Service<
  CreateProjectServiceVariables,
  CreatetProjectServiceResponse
> = ({ data }) => {
  return Request.post(CREATE_PROJECT_URL, data);
};

export const deleteProject: Service<
  DeleteProjectServiceVariables,
  DeleteProjectServiceResponse
> = ({ params }) => {
  return Request.delete(DELETE_PROJECT_URL, params);
};

export const archiveProject: Service<
  ArchiveProjectServiceVariables,
  ArchiveProjectServiceResponse
> = ({ data, params }) => {
  return Request.put(ARCHIVE_PROJECT_URL, data, { params });
};

export const getLastProjects: Service<null, GetProjectServiceResponse> = () => {
  return Request.get(GET_LAST_PROJECTS, null);
};

export const getProjectsUser: Service<
  GetProjectUserServiceVariables,
  GetProjectUserServiceResponse
> = (params) => {
  return Request.get(GET_PROJECT_USERS_URL, params);
};

export const shareProject: Service<
  ShareProjectServiceVariables,
  ShareProjectServiceResponse
> = ({ data }) => {
  return Request.post(POST_SHARE_PROJECT_URL, data);
};

export const undoShareProject: Service<
  UndoShareProjectServiceVariables,
  UndoShareProjectServiceResponse
> = ({ data }) => {
  return Request.post(POST_UNDO_SHARE_PROJECT_URL, data);
};
