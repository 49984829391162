import { NotificationItemProps } from "./types";
import { RadioButtonUnchecked, RadioButtonChecked } from "@mui/icons-material";
import classes from "./style.module.css";
import { Link } from "react-router-dom";
import { isJSON } from "app/utils/isJson";
import { useMutation } from "@tanstack/react-query";
import { readNotification } from "services/webapp/notifications/endpoints";
import useToggle from "hooks/Toggle/useToogle";
import { IconButton } from "@mui/material";
const NotificationItem: React.FunctionComponent<NotificationItemProps> = ({
  notification,
}) => {
  const [toggle, setToggle] = useToggle(notification.is_active);
  const { mutate } = useMutation(readNotification);
  let contentParse;
  if (isJSON(notification.content)) {
    contentParse = JSON.parse(notification.content) as {
      text: string;
      projectID: string;
    };
  } else {
    contentParse = { text: notification.content, projectID: "" };
  }
  const updateReadNotification = () => {
    setToggle();
    mutate({
      data: {
        notification_id: notification.id,
      },
    });
  };
  return (
    <div className={classes.notification}>
      <div className={classes.notification_content}>
        {contentParse.text}
        <Link
          className={classes.notification_url}
          to={`/project?p=${contentParse.projectID}`}
        >
          Go Project
        </Link>
      </div>

      <div className={classes.notification_from}>
        From : {notification.from || "No Name"}
      </div>

      <IconButton
        className={toggle ? classes.btn_read : classes.btn}
        onClick={updateReadNotification}
        disabled={!toggle}
      >
        {!toggle ? (
          <RadioButtonChecked color="primary" />
        ) : (
          <RadioButtonUnchecked />
        )}
      </IconButton>
    </div>
  );
};
export default NotificationItem;
