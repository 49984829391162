import React from "react";

import { AxisContainer, XAxis } from "./Axis.styled";
import { AxisProps } from "./types";
import { rangeInteger } from "app/utils/math/range";

const Axis: React.FunctionComponent<AxisProps> = () => {
  return (
    <AxisContainer>
      {rangeInteger(0, 200).map(() => (
        <XAxis />
      ))}
    </AxisContainer>
  );
};

export default Axis;
