import React from "react";
import { Button, Divider, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";

import LandingLayout from "layout/Landing/LandingLayout";
import Form from "components/Form/Form";

import authServices from "services/webapp/auth";

import {
  FormContent,
  FormActions,
  FormTitle,
} from "components/Form/Form.styled";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAuthStore from "context/auth-store";
import Link from "components/Link";

import Logo from "../components/Logo";
import useToggle from "hooks/Toggle/useToogle";
import PrivacyPolicyModal from "../components/PrivacyPolicyModal";
import TermsOfServiceModal from "../components/TermsOfServiceModal";
import "../style.scss";
const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

const SignInPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const { setAccessToken } = useAuthStore();
  const [privacyModal, togglePrivacyModal] = useToggle();
  const [termsModal, toggleTermsModal] = useToggle();

  const loginUser = (form: any) => {
    toast.loading("Hello", { autoClose: false, toastId: "login" });
    authServices
      .login({ data: form })
      .then((res) => {
        const accessToken = res.data.data.accessToken;
        if (accessToken) {
          toast.update("login", {
            render: "Login successful! Welcome back",
            type: "success",
            autoClose: 3000,
            closeOnClick: true,
            isLoading: false,
          });
          setAccessToken(accessToken);
          navigate("/map");
        } else {
          toast.update("login", {
            render: "Incorrect email or password",
            type: "error",
            autoClose: 3000,
            closeOnClick: true,
            isLoading: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <LandingLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
        }}
        className="fade-in"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.4)",
            alignItems: "center",
            minHeight: "100vh",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Logo />
          <Form width={400} onSubmit={handleSubmit(loginUser)}>
            <FormTitle> Log in to continue</FormTitle>
            <FormContent>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="email"
                    helperText={errors.email?.message}
                    error={Boolean(errors.email)}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="password"
                    type="password"
                    helperText={errors.password?.message}
                    error={Boolean(errors.password)}
                    {...field}
                  />
                )}
              />
            </FormContent>
            <FormActions>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit(loginUser)}
              >
                Sign In
              </Button>
            </FormActions>
            <Divider sx={{ marginTop: "12px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                padding: "12px",
              }}
            >
              <Link to="/forget_password">Can't log in? </Link>
              <Link to="/sign-up">Sign up for an account</Link>
            </div>
          </Form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "12px",
              marginTop: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "12px",
              borderRadius: "4px",
              width: "400px",
            }}
          >
            <Link onClick={() => togglePrivacyModal()}>Privacy Policy </Link>
            <Link onClick={() => toggleTermsModal()}>Terms of Service </Link>
          </div>
        </div>
      </div>
      <PrivacyPolicyModal isOpen={privacyModal} onClose={togglePrivacyModal} />
      <TermsOfServiceModal isOpen={termsModal} onClose={toggleTermsModal} />
    </LandingLayout>
  );
};

export default SignInPage;
