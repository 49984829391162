import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { Table } from "components/Table";
import SubPageContainer from "components/SubPageContainer/SubPageContainer";
import { ProjectModel } from "services/webapp/models";
import { getProjects } from "services/webapp/projects/endpoints";

import ProjectTableRow from "./components/ProjectTableRow";

const ProjectsPage: React.FunctionComponent = () => {
  const [queryParameters] = useSearchParams();
  const page = queryParameters.get("page");
  const [pages, setPages] = useState(1);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["projects", page],
    queryFn: () => getProjects({ page: page }),
  });

  const navigate = useNavigate();
  const [projects, setProjects] = useState<Array<ProjectModel>>([]);

  useEffect(() => {
    if (!isLoading) {
      if (data?.data) {
        setProjects(data?.data?.results || []);
        setPages(data?.data?.metadata?.pages);
      } else {
        setProjects([]);
      }
    }
  }, [isLoading, data]);

  const pageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    navigate(`?page=${page}`);
  };

  return (
    <SubPageContainer
      title={`Projects (${data?.data?.metadata?.count || 0})`}
      onClose={() => navigate("/map")}
    >
      <Table
        head={["Project name", "Location", "Created on", "", ""]}
        pages={pages}
        isEmpty={!projects.length}
        isLoading={isLoading}
        onPageChange={pageChange}
        currentPage={Number(page)}
      >
        {projects &&
          projects.map((row, index) => {
            return (
              <ProjectTableRow
                key={index + "table"}
                project={row}
                getProjects={() => refetch()}
              />
            );
          })}
      </Table>
    </SubPageContainer>
  );
};
export default ProjectsPage;
