import React from "react";

import { Table, TableCell, TableRow } from "components/Table";
import classes from "./style.module.css";

const PaymentPage: React.FunctionComponent = () => {
  return (
    <>
      <div className={classes.container}>
        <Table head={["License", "payment date", "price", ""]}>
          <TableRow key={""}>
            <TableCell>Plan-1</TableCell>
            <TableCell>01.03.2000</TableCell>
            <TableCell>price</TableCell>
            <TableCell noPadding>
              <button>Download</button>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </>
  );
};

export default PaymentPage;
