export const DarkTheme = {
  backgroundColor: "#000000",
  layout: {
    content: {
      bgColor: "#141416",
    },
  },
  header: {
    bgColor: "#141416",
  },
  sidebar: {
    bgColor: "#141416",
    logo: {
      bg: "#141416",
    },
    link: {
      color: "#ffffff",
    },
  },
  font: {
    color: "#ffffff",
  },
  table: {
    bg: "#202020",
    cell: {
      color: "#ffffff",
    },
  },
};
