import React from "react";
import { Box, Button, TableCell, TableRow, TextField } from "@mui/material";
import Modal from "components/UiKit/Modal/Modal";
import { Table } from "components/Table";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createMember,
  getOrganizationMembers,
} from "services/webapp/organization/endpoints";
import useToggle from "hooks/Toggle/useToogle";
import Form from "components/Form/Form";
import { Controller, useForm } from "react-hook-form";
import {
  FormActions,
  FormContent,
  FormTitle,
} from "components/Form/Form.styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddUserForm, addUserSchema } from "./form/form.scheme";
import useUserStore from "context/user-store";

const UsersPage: React.FunctionComponent = () => {
  const user = useUserStore((state) => state.user);
  const [openAddUserModel, handleOpenAddUserModal] = useToggle();
  const { control, handleSubmit } = useForm<AddUserForm>({
    resolver: yupResolver(addUserSchema),
  });
  const { data } = useQuery({
    queryKey: ["users"],
    queryFn: () => getOrganizationMembers({}),
  });
  const { mutate } = useMutation(createMember);

  const addUser = (form: AddUserForm) => {
    mutate({ data: form });
  };
  return (
    <>
      {user.auth === "admin" && (
        <>
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={handleOpenAddUserModal}
            >
              Add User
            </Button>
          </Box>
        </>
      )}

      <Table head={["Name", "Email", "Role"]}>
        {data?.data.data.users?.map((d) => (
          <TableRow>
            <TableCell>{d.name || "--------"}</TableCell>
            <TableCell>{d.email}</TableCell>
            <TableCell>{d.auth}</TableCell>
            {/* {user.auth === "admin" ? (
              <>
                <TableCell>{<div>delete</div>}</TableCell>
              </>
            ) : (
              <>
                <TableCell></TableCell>
              </>
            )} */}
          </TableRow>
        ))}
      </Table>
      <Modal open={openAddUserModel} handleClose={handleOpenAddUserModal}>
        <Form width={400}>
          <FormTitle> Add Member</FormTitle>
          <FormContent>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <TextField label="Email" {...field} />}
            />
          </FormContent>
          <FormActions>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(addUser)}
            >
              Add User
            </Button>
          </FormActions>
        </Form>
      </Modal>
    </>
  );
};

export default UsersPage;
