//@ts-nocheck
import styled from "@emotion/styled";

export const RulerContainer = styled("div")(
  (props: { rulerHeight: number }) => ({
    position: "relative",
    height: props.rulerHeight * 25 + "px",
    minWidth: "100px",
    maxWidth: "100px",
    backgroundColor: "rgba(255, 87, 51,0.3)",
    zIndex: 1,
  })
);
export const RulerContent = styled("div")({
  position: "absolute",
  top: "-12px",
});

export const UnitRulerComponent = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "25px",
  position: "relative",
});

export const UnitRulerValue = styled("div")({
  fontWeight: "600",
  fontSize: "14px",
});

export const UnitRulerLine = styled("div")(
  (props: { width: number | string }) => ({
    width: props.width,
    borderBottom: "2px #000000 solid",
  })
);
