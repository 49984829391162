import React from "react";
import { LayersPreviewProps } from "./types";
import { LayersContainer } from "./Layers.styled";
import SingleLayer from "./SingleLayer";

const LayersPreview: React.FunctionComponent<LayersPreviewProps> = ({
  layers,
  boreholeLevel,
  elevation,
}) => {
  const startLayers = elevation > boreholeLevel ? elevation - boreholeLevel : 0;
  return (
    <LayersContainer sx={{ marginTop: startLayers * 25 + "px" }}>
      {layers.map((layer) => (
        <SingleLayer layer={layer} />
      ))}
    </LayersContainer>
  );
};

export default LayersPreview;
