import styled from "@emotion/styled";

export const LabLayoutContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "90vh",
  gap: "20px",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#ffffff",
});

export const LabLayoutContainer = styled("div")({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  height: "100vh",
  width: "100%",
  zIndex: 1000,
});
