import React from "react";
import { Typography } from "@mui/material";

import Modal from "components/Modal";

const TermsOfServiceModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  return (
    <Modal title="Terms of Service" isOpen={isOpen} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
          height: "600px",
          overflow: "scroll",
        }}
      >
        <Typography>
          **TERMS OF SERVICE** **Last Updated: September 8, 2023** Please read
          these Terms of Service ("Terms") carefully before using [Your Website
          or App Name] (the "Service") operated by [Your Company Name] ("us,"
          "we," or "our"). By accessing or using the Service, you agree to be
          bound by these Terms. If you disagree with any part of these Terms,
          please do not use the Service.
        </Typography>
        <Typography>
          **1. ACCEPTANCE OF TERMS** By accessing or using the Service, you
          acknowledge that you have read, understood, and agree to be bound by
          these Terms and our Privacy Policy. If you do not agree to these Terms
          or the Privacy Policy, please do not use the Service.
        </Typography>
        <Typography>
          **2. USE OF THE SERVICE** 2.1. **Eligibility**: You must be at least
          18 years old to use the Service. By using the Service, you represent
          and warrant that you are at least 18 years old. 2.2. **User Account**:
          To use certain features of the Service, you may be required to create
          a user account. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account. You agree to notify us immediately of
          any unauthorized use of your account. 2.3. **User Content**: You may
          submit content to the Service, such as text, images, or other
          materials ("User Content"). You retain ownership of your User Content,
          but by submitting it, you grant us a worldwide, non-exclusive,
          royalty-free, and sublicensable license to use, reproduce, modify,
          adapt, publish, translate, distribute, and display your User Content
          in connection with the Service. 2.4. **Prohibited Conduct**: You agree
          not to: a. Violate any applicable laws or regulations. b. Use the
          Service for any illegal or unauthorized purpose. c. Attempt to gain
          unauthorized access to the Service. d. Interfere with or disrupt the
          Service or servers connected to it. e. Engage in any form of automated
          data collection, such as scraping. f. Impersonate any person or entity
          or misrepresent your affiliation with any person or entity.
        </Typography>
        <Typography>
          **3. INTELLECTUAL PROPERTY** The Service and its original content
          (excluding User Content) are protected by copyright, trademark, and
          other laws. You acknowledge and agree that we own all right, title,
          and interest in and to the Service and its content. You may not use
          our trademarks or copyrighted materials without our prior written
          consent.
        </Typography>
        <Typography>
          **4. DISCLAIMERS** 4.1. **No Warranty**: The Service is provided on an
          "as-is" and "as-available" basis without any warranties, either
          expressed or implied, including but not limited to warranties of
          merchantability, fitness for a particular purpose, or
          non-infringement. 4.2. **Limitation of Liability**: In no event shall
          we be liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, arising out of your access to or use of the
          Service.
        </Typography>
        <Typography>
          **5. PRIVACY** Your use of the Service is also governed by our Privacy
          Policy, which can be found at [link to Privacy Policy]. By using the
          Service, you consent to the collection and use of your personal
          information as described in the Privacy Policy.
        </Typography>
        <Typography>
          **6. CHANGES TO TERMS** We reserve the right to modify these Terms at
          any time. If we make changes, we will post the updated Terms on the
          Service and indicate the date of the latest revision. Your continued
          use of the Service after the revised Terms are posted will constitute
          your acceptance of the changes.
        </Typography>
        <Typography>
          **7. TERMINATION** We reserve the right to terminate or suspend your
          access to the Service at our sole discretion, without notice, for any
          reason, including but not limited to a breach of these Terms.
        </Typography>
        <Typography>
          **8. GOVERNING LAW** These Terms are governed by and construed in
          accordance with the laws of [Your Jurisdiction]. Any disputes arising
          under or in connection with these Terms shall be subject to the
          exclusive jurisdiction of the courts located in [Your Jurisdiction].
        </Typography>
        <Typography>
          **9. CONTACT US** If you have any questions or concerns about these
          Terms, please contact us at [contact email or address].
        </Typography>
        <Typography>Thank you for using [Your Website or App Name]!</Typography>
      </div>
    </Modal>
  );
};

export default TermsOfServiceModal;
