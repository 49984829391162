import React from "react";

import { CustomIcon } from "./Icon.styled";
import { IconProps } from "./types";

const Icon: React.FunctionComponent<IconProps> = ({
  icon,
  width = 25,
  height = 25,
  borderRadius,
  ...props
}) => {
  return (
    <CustomIcon
      src={icon}
      alt="icon"
      width={width}
      height={height}
      sx={{ borderRadius: borderRadius }}
      {...props}
    />
  );
};
export default Icon;
