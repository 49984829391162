import { NOMINATIM_SEARCH_URL } from "./constants";
import { NominatimServiceResponse, NominatimServiceVariables } from "./types";

export const searchLocation = ({
  params,
}: NominatimServiceVariables): Promise<NominatimServiceResponse> => {
  return fetch(NOMINATIM_SEARCH_URL(params.search), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  });
};
