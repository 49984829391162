import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Alert, Pagination } from "@mui/material";

import Loader from "components/Feedback/Loader";
import PageModal from "components/PageModal";
import { getNotifications } from "services/webapp/notifications/endpoints";

import NotificationItem from "./NotificationItem/NotificationItem";
import classes from "./style.module.css";

const NotificationsPage: React.FunctionComponent = () => {
  const [queryParameters] = useSearchParams();
  const page = Number(queryParameters.get("page") || 1);
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["notifications", page],
    queryFn: () => getNotifications({ page }),
  });
  const notifications = data?.data.results || [];
  const pagenumber = data?.data.metadata.pages;
  const navigate = useNavigate();
  const changePageHandler = (_: any, page: number) => {
    navigate(`/notifications?page=${page}`);
  };
  return (
    <PageModal title="Notifications" onClose={() => navigate("/map")}>
      <div className={classes.notification_list}>
        <div className={classes.notification_list_content}>
          {isLoading && <Loader />}
          {isSuccess &&
            notifications.map((n, i) => (
              <NotificationItem key={i + n.from} notification={n} />
            ))}
          {isSuccess && notifications.length === 0 && (
            <Alert color="info">There is no notification</Alert>
          )}
        </div>
        <div className={classes.notification_list_pagination}>
          {notifications.length !== 0 && (
            <Pagination
              onChange={changePageHandler}
              page={page}
              count={pagenumber}
            />
          )}
        </div>
      </div>
    </PageModal>
  );
};

export default NotificationsPage;
