import React from "react";
import LandingLayout from "layout/Landing/LandingLayout";

import { LandingArea } from "./components/Landing/Landing.styled";
import { Button, Typography } from "@mui/material";

import Icon from "components/Icon";
import pdIcon from "app/assets/icon/pile.png";
import { useNavigate } from "react-router-dom";
import "./Landing.scss";
import PricingList from "./components/Pricing/PricingList";

const LandingPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <LandingLayout>
      <LandingArea>
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              padding: "100px",
              width: "100%",
            }}
          >
            <Icon
              className="icon-animate"
              icon={pdIcon}
              borderRadius={"25%"}
              width={100}
              height={100}
            />
            <Typography
              className="fade-in-text"
              fontSize={70}
              color="#FF9030"
              fontWeight={"bold"}
              letterSpacing={"-2px"}
              lineHeight={"75px"}
              sx={{
                background: "linear-gradient(to bottom, orange, #f67700)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Piles Capacity
            </Typography>
            <div style={{ padding: "10px" }}></div>
            <Typography
              className="fade-in-text"
              fontSize={50}
              color="#ffffff"
              fontWeight={"bold"}
              letterSpacing={"-2px"}
              lineHeight={"45px"}
              sx={{
                textShadow:
                  "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Geotechnical Design Tool for
              <br />
              Cast-in-situ Bored and CFA Piles
            </Typography>
            <div style={{ padding: "10px" }}></div>
            <Typography
              className="fade-in-text"
              fontSize={36}
              color="#ffffff"
              fontWeight={"bold"}
              letterSpacing={"-2px"}
              lineHeight={"40px"}
              sx={{
                textShadow:
                  "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Visualise soil profile, analyse bearing capacity graphs,
              <br />
              record project location, and share to collaborate on alternative
              designs.
            </Typography>
            <div style={{ padding: "10px" }}></div>
            <Typography
              className="fade-in-text"
              fontSize={24}
              color="#ffffff"
              sx={{
                textShadow:
                  "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              7-day free trial and flexible subscription or perpetual license
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                marginTop: "20px",
              }}
            >
              <Button
                className="slide-in-button-left"
                onClick={() => navigate("/sign-up")}
                sx={{
                  width: "150px",
                  fontSize: "16px",
                  padding: "12px",
                  backgroundColor: "orange",
                }}
                variant="contained"
              >
                Sign Up
              </Button>
              <Button
                className="slide-in-button "
                onClick={() => navigate("/sign-in")}
                sx={{
                  width: "150px",
                  padding: "12px",
                  fontSize: "16px",
                  backgroundColor: "#fff",
                  color: "#202020",
                  ":hover": {
                    backgroundColor: "#cccccc",
                  },
                }}
                variant="contained"
              >
                Log In
              </Button>
            </div>
            <div style={{ marginTop: "25px" }}>
              <a
                style={{
                  display: "inline-block",
                  padding: "12px 20px",
                  textDecoration: "none",
                  backgroundColor: "orange",
                  color: "#ffffff",
                  width: "200px",
                  borderRadius: "4px",
                }}
                href="#pricing_area"
              >
                Pricing
              </a>
            </div>
          </div>
        </div>
      </LandingArea>
      <div id="pricing_area">
        <PricingList />
      </div>
    </LandingLayout>
  );
};

export default LandingPage;
