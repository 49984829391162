import UiKitModal from "components/UiKit/Modal/Modal";
import { ModalProps } from "./types";
import { IconButton } from "@mui/material";

import classes from "./modal.module.scss";
import { Close } from "@mui/icons-material";
const Modal: React.FunctionComponent<ModalProps> = ({
  isOpen,
  children,
  title,
  onClose,
}) => {
  return (
    <UiKitModal open={isOpen} handleClose={onClose}>
      <div className={classes.modal}>
        <div className={classes.modal_header}>
          <div className={classes.modal_title}>{title}</div>
          <div className={classes.modal_header__action}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>

        {children}
      </div>
    </UiKitModal>
  );
};

export default Modal;
