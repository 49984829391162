//@ts-nocheck
import React from "react";
import {
  PileBody,
  PileCircleBottom,
  PileCircleTop,
  PileContainer,
} from "./Pile.styled";
import { PileProps } from "./types";
import { Tooltip, Typography } from "@mui/material";

const SinglePile: React.FunctionComponent<PileProps> = ({
  pile,
  pileId,
  pileWidth,
  pileHeight,
  elevation,
  pileCutoffLevel,
  handleAnalysisModal,
  backgroundColor,
  isAnalysis = false,
}) => {
  const pileStart =
    elevation > pileCutoffLevel ? elevation - pileCutoffLevel : 0;
  return (
    <Tooltip
      title={
        <div style={{ padding: "20px", fontSize: "18px" }}>
          <div>Length: {pile.length}m</div>
          <div>Diameter: {pile.diameter}m</div>
          <div>CutOff Level: {pile.cutoff_level}m</div>
        </div>
      }
      placement="top"
    >
      <PileContainer
        pileWidth={pileWidth * 50}
        pileHeight={pileHeight * 25}
        pileCutoffLevel={pileStart * 25 + "px"}
        backgroundColor={backgroundColor}
        onClick={() => handleAnalysisModal(pileId)}
      >
        <Typography
          sx={{
            zIndex: 1,
            textAlign: "center",
            marginTop: "12px",
            color: "#ffffff",
          }}
        >
          {pile.pileName}
        </Typography>
        <PileCircleTop backgroundColor={backgroundColor} />
        <PileBody />
        <PileCircleBottom backgroundColor={backgroundColor} />
      </PileContainer>
    </Tooltip>
  );
};

export default SinglePile;
