import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LabLayout from "layout/Lab";
import { getDesign } from "services/webapp/designs/endpoints";
import { getReport } from "services/webapp/reports/endpoints";
import { LabContent } from "./components/LabContent/Lab.styled";
import LabStepsBar from "./components/LabStepsBar";
import { LayersStep, PilesStep, PreviewStep } from "./steps";
import useLabStore from "context/lab-store";
import {
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  SpeedDial,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ErrorRounded,
  PlayArrowOutlined,
  RestartAlt,
} from "@mui/icons-material";
import { analysisService } from "services/webapp/analysis/endpoints";
import useToggle from "hooks/Toggle/useToogle";

const LabPage: React.FunctionComponent = () => {
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [loader, setLoader] = useState(true);
  const [loaderAnalysis, setLoaderAnalysis] = useState(false);
  const [searchParams] = useSearchParams();
  const [toggle, handleToggle] = useToggle();
  const { setLabData, errors, setReports, clear, isAnalysis } = useLabStore(
    (state) => state
  );

  const getDesignServiceHandler = useCallback(async () => {
    const designId = searchParams.get("d") || "";
    if (designId) {
      const res = await getDesign({ params: { designId } });
      const design = res.data.data.designs;
      const layerWithProfile = JSON.parse(design.layers_data) as {
        layers: object;
        profile: object;
      };
      const pilesWithFrictions = JSON.parse(design.piles_data) as {
        piles: object;
        frictions: object;
      };
      setLabData("project_id", design.project_id || "");
      setLabData("layers", layerWithProfile?.layers || []);
      setLabData("piles", pilesWithFrictions?.piles || []);
      setLabData("design_name", design.design_name);
      setLabData("isAnalysis", design.is_analysis);
      if (pilesWithFrictions?.frictions) {
        setLabData("frictions", pilesWithFrictions?.frictions);
      }

      if (layerWithProfile?.profile) {
        setLabData("profile", layerWithProfile?.profile);
      }

      setLoader(false);
    }
  }, [setLabData, searchParams]);

  const getReportsHandler = useCallback(async () => {
    const designId = searchParams.get("d") || "";

    const res = await getReport({ params: { designId } });
    if (res.data.type) {
      setReports(res.data.data[0].data);
    }
  }, [searchParams, setReports]);

  useEffect(() => {
    clear();
    setReports([]);
    getDesignServiceHandler();
    getReportsHandler();
  }, [
    getDesignServiceHandler,
    getReportsHandler,
    searchParams,
    setReports,
    clear,
  ]);

  // const actions = [
  //   {
  //     name: "Analysis Run",
  //     icon: <AnalyticsOutlined />,
  //     tooltipTitle: "Analysis Run",
  //   },
  // ];
  const checkAnalysisStatus = () => {
    if (errors.length > 0) {
      handleToggle();
      return false;
    }
    return true;
  };

  const analysis = async () => {
    const analysisStatus = checkAnalysisStatus();
    if (!analysisStatus) return;
    setLoaderAnalysis(true);
    const res = await analysisService({
      params: { designId: searchParams.get("d") || "" },
    });
    if (res && res.data.type) {
      setReports(res.data.data);
      setLabData("isAnalysis", true);
    } else {
      setLabData("isAnalysis", false);
    }
    setLoaderAnalysis(false);
  };

  const resetAnalysis = async () => {
    // console.log("dialog open");
  };
  return (
    <LabLayout>
      {loader && <div>loading</div>}
      {!loader && (
        <>
          <LabStepsBar
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
          />
          <LabContent>
            {selectedStep === 0 && <LayersStep />}
            {selectedStep === 1 && <PilesStep />}
            {selectedStep === 2 && <PreviewStep />}
          </LabContent>
        </>
      )}
      {selectedStep === 2 && (
        <Tooltip
          placement="left-start"
          title={
            <Typography>
              {" "}
              {isAnalysis ? "ReAnalysis" : "Run Analysis"}
            </Typography>
          }
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              "& .MuiButtonBase-root": {
                backgroundColor: errors.length ? "red" : "#1976D2",
              },
            }}
            onClick={isAnalysis ? resetAnalysis : analysis}
            icon={
              isAnalysis ? (
                <RestartAlt />
              ) : errors.length ? (
                <ErrorRounded />
              ) : (
                <PlayArrowOutlined />
              )
            }
          />
        </Tooltip>
      )}

      <Modal
        open={toggle}
        onClose={() => handleToggle()}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          {errors.map((err) => (
            <Box sx={{ padding: "4px" }}>
              <Box>Layers:{err.index}</Box>
              <Box>Property:{err.name}</Box>
              <Box>Message:{err.message}</Box>
            </Box>
          ))}
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderAnalysis}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LabLayout>
  );
};

export default LabPage;
