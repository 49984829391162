import React, { useState, useEffect } from "react";
import MuiStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { StepperProps } from "./types";
import { useTheme } from "@mui/material";
import classes from "./style.module.css";

const Stepper: React.FunctionComponent<StepperProps> = ({
  steps,
  setSelectedStep,
  disabledNext,
  disabledBack,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    setSelectedStep(activeStep);
  }, [activeStep, setSelectedStep]);
  const { customTheme } = useTheme();

  const handleStepperButton = (type: "next" | "back") => {
    if (type === "next") {
      setActiveStep((prev) => prev + 1);
      return;
    }
    setActiveStep((prev) => prev - 1);
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
      }}
    >
      <button
        onClick={() => handleStepperButton("back")}
        disabled={activeStep === 0}
        className={classes.stepper_btn}
      >
        Previous Screen
      </button>
      <MuiStepper style={{ width: "100%" }} activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => setActiveStep(index)}>
            <StepLabel
              sx={{
                "&:hover": { cursor: "pointer" },
                "span.Mui-active ,span.Mui-completed, span.Mui-disabled": {
                  color: customTheme.font.color,
                },
              }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
      <button
        disabled={activeStep === 2 || disabledNext}
        onClick={() => handleStepperButton("next")}
        className={classes.stepper_btn}
      >
        Next Screen
      </button>
    </div>
  );
};

export default Stepper;
