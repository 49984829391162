import { Button } from "@mui/material";
import useLanguageStore from "context/lang-store";
import { useTranslation } from "react-i18next";

const LanguageSettings: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  const { setLanguage, language } = useLanguageStore();

  const handleLanguage = (lang: string) => async () => {
    await i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "16px",
      }}
    >
      <Button
        onClick={handleLanguage("en")}
        variant={language === "en" ? "contained" : "outlined"}
        size="small"
      >
        English
      </Button>
      <Button
        onClick={handleLanguage("tr")}
        variant={language === "tr" ? "contained" : "outlined"}
        size="small"
      >
        Turkish
      </Button>
      <Button
        onClick={handleLanguage("ru")}
        variant={language === "ru" ? "contained" : "outlined"}
        size="small"
      >
        Russian
      </Button>
    </div>
  );
};
export default LanguageSettings;
