import React from "react";
import { CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import classes from "./style.module.scss";
import { PageModalProps } from "./types";

const PageModal: React.FunctionComponent<PageModalProps> = ({
  children,
  title,
  onClose,
}) => {
  return (
    <div className={classes.pageModal_container}>
      <div className={classes.pageModal}>
        <div className={classes.pageModal_header}>
          <div className={classes.pageModal_header_title}>
            <h3>{title}</h3>
          </div>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>
        <div className={classes.pageModal_content}>{children}</div>
      </div>
    </div>
  );
};

export default PageModal;
