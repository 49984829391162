/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

import useChart from "hooks/Chart/useChart";
import { AllowableChartProps } from "./types";
import useLabStore from "context/lab-store";

const UltimateChart: React.FunctionComponent<AllowableChartProps> = ({
  analysisResult,
  pileId,
}) => {
  const [analysisResultInformation, setAnalysisResultInformation] = useState<{
    depths: Array<number>;
    ultimate_tip_resistances: Array<number>;
    ultimate_skin_frictions: Array<number>;
    ultimate_bearing_capacities: Array<number>;
    levels: Array<number>;
  } | null>(null);
  const { profile, piles } = useLabStore();
  const findHeight = () => {
    let max = 0;
    let p = piles[pileId];
    const height =
      Math.abs(Number(profile?.borehole_level)) -
      Math.abs(p.cutoff_level) +
      p.length;
    if (height > max) {
      max = height;
    }
    return max;
  };
  const maxValue = findHeight();
  const minValue = Number(profile?.borehole_level) - findHeight();
  const { chart, setOption } = useChart({
    height: maxValue * 25 + 27 + "px",
  });

  useEffect(() => {
    if (analysisResult) {
      setAnalysisResultInformation(analysisResult);
    }
  }, [analysisResult, setAnalysisResultInformation]);

  useEffect(() => {
    if (analysisResultInformation) {
      setOption({
        xAxis: {
          position: "top",
          type: "value",
          axisLine: {
            show: false, // Hide full Line
          },
          xisTick: {
            show: false, // Hide Ticks,
          },
          splitLine: {
            lineStyle: {
              color: "#aaaaaa",
            },
          },
          show: true,
        },
        yAxis: {
          verticalAlign: "end",
          show: false,
          type: "value",
          max: profile?.borehole_level,
          min: minValue,
        },
        legend: {
          data: ["Bearing", "Skin", "Tip"],
          orient: "horizontal",
          bottom: 10,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: 60,
          top: 20,
          right: 60,
          bottom: 10,
          show: false,
        },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
            realTime: true,
            yAxisIndex: [0, 1],
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        series: [
          {
            data:
              analysisResultInformation.ultimate_skin_frictions.map(
                (item, index) => [item, analysisResultInformation.levels[index]]
              ) || [],
            name: "Skin",
            type: "line",
            smooth: true,
            showSymbol: false,
          },
          {
            data:
              analysisResultInformation.ultimate_tip_resistances.map(
                (item, index) => [item, analysisResultInformation.levels[index]]
              ) || [],
            name: "Tip",
            type: "line",
            smooth: true,
            showSymbol: false,
          },
          {
            data:
              analysisResultInformation.ultimate_bearing_capacities.map(
                (item, index) => [item, analysisResultInformation.levels[index]]
              ) || [],
            type: "line",
            name: "Bearing",
            smooth: true,
            showSymbol: false,
          },
        ],
      });
    }
  }, [setOption, analysisResultInformation]);

  return <div style={{ width: "100%", height: "50%" }}> {chart}</div>;
};

export default UltimateChart;
