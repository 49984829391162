import { styled } from "@mui/material";

export const PreviewContainer = styled("div")({
  display: "flex",
  position: "relative",
  height: "80vh",
  width: "100%",
  padding: "40px 0px 0px  0px",
  overflowX: "auto",
  overflowY: "auto",
});
