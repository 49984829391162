import { Request } from "services/_base";
import { Service } from "services/types";
import { POST_CREATE_DESIGN_URL } from "../designs/constants";
import {
  GetOrganizationServiceVariable,
  GetOrganizationServiceResponse,
  GetOrganizationMembersServiceVariable,
  GetOrganizationMembersServiceResponse,
  CreateOrganisationMemberServiceVariable,
  CreateOrganisationMemberServiceResponse,
} from "./types";

export const getOrganization: Service<
  GetOrganizationServiceVariable,
  GetOrganizationServiceResponse
> = () => {
  return Request.get(POST_CREATE_DESIGN_URL, {});
};

export const getOrganizationMembers: Service<
  GetOrganizationMembersServiceVariable,
  GetOrganizationMembersServiceResponse
> = () => {
  return Request.get("organization/members", {});
};

export const createMember: Service<
  CreateOrganisationMemberServiceVariable,
  CreateOrganisationMemberServiceResponse
> = ({ data }) => {
  return Request.post("organization/members", data, {});
};
