import { Request } from "services/_base";
import { Service } from "services/types";

export const createTokenMapkit: Service<{}, { token: string }> = () => {
  return Request.get(`/apple/mapkit/createToken`, {});
};

export const createTokenMapkitLocation: Service<{}, string> = () => {
  return Request.get(`/apple/location/createToken`, {});
};
