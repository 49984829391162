import { Request } from "services/_base";
import { Service } from "services/types";
import { PostAnalysisResponse, PostAnalysisVariables } from "./types";

export const analysisService: Service<
  PostAnalysisVariables,
  PostAnalysisResponse
> = ({ params }) => {
  return Request.post(`analysis/${params.designId}`, {});
};
