import React from "react";
import UpdateName from "./UpdateName";
import UpdateEmail from "./UpdateEmail";
import UpdatePassword from "./UpdatePassword";
import UserRole from "./Role";

const ProfilePage: React.FunctionComponent = () => {
  return (
    <div>
      <UpdateName />
      <UpdateEmail />
      <UpdatePassword />
      <UserRole />
    </div>
  );
};

export default ProfilePage;
