import CreateProject from "containers/CreateProject/CreateProjectModal";
import useProjectStore from "context/project-store";

const CreateProjectsPage = () => {
  const { location } = useProjectStore();
  return (
    <>
      {location && (
        <CreateProject
          location={{
            lat: location?.coordinate?.latitude || 0,
            lng: location?.coordinate?.longitude || 0,
            address: {
              country: location.country,
            },
            displayName: location?.name || "",
          }}
        />
      )}
    </>
  );
};
export default CreateProjectsPage;
