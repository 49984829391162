import { styled } from "@mui/material";

export const MockHTML = styled("div")(({ theme }) => {
  const { customTheme } = theme;
  return {
    minHeight: "fit-content",
    backgroundColor: customTheme.backgroundColor,
    overflowY: "auto",
  };
});
