import { styled } from "@mui/material";

export const SingleThemeContainer = styled("div")(
  (props: { isSelected: boolean }) => ({
    ":hover": {
      cursor: "pointer",
    },
    height: "200px",
    boxShadow: props.isSelected ? "0px 0px 5px 0.3px #FF5733 " : "initial",
  })
);
