import React from "react";
import { Modal as MuiModal } from "@mui/material";
import { ModalProps } from "./types";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000000,
};

const UiKitModal: React.FunctionComponent<ModalProps> = ({
  children,
  open,
  actions,
  handleClose,
}) => {
  return (
    <MuiModal open={open} onClose={handleClose} sx={style}>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "20px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          {actions}
        </div>
      </div>
    </MuiModal>
  );
};

export default UiKitModal;
