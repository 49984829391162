import { create } from "zustand";

import { ProjectStoreType } from "./types";

const useProjectStore = create<ProjectStoreType>()((set) => ({
  location: null,
  setLocation: (location) => set(() => ({ location: location })),
  setShowLocation: (isShow) => set(() => ({ isShowlocations: isShow })),
  isShowlocations: false,
  projectCount: 0,
  setProjectCount: (projectCount) =>
    set(() => ({ projectCount: projectCount })),
}));

export default useProjectStore;
