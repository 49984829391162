import UiKitModal from "components/UiKit/Modal/Modal";
import { ResetLabModalProps } from "./types";
import { Alert, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { deleteReport } from "services/webapp/reports/endpoints";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useLabStore from "context/lab-store";
import Loader from "components/Feedback/Loader";
import classes from "./resetlabmodal.module.scss";

const ResetLabModal: React.FunctionComponent<ResetLabModalProps> = ({
  isOpen,
  toggleModal,
}) => {
  const [searchParams] = useSearchParams();
  const { clear } = useLabStore();
  const navigate = useNavigate();
  const { project_id } = useLabStore();
  const designId = searchParams.get("d") || "";
  const { isSuccess, isLoading, mutate } = useMutation(deleteReport);
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [clear, isSuccess]);

  return (
    <UiKitModal
      isLoading={isLoading}
      actions={
        <div className={classes.modal_actions}>
          <Button
            onClick={() => navigate(`/project?p=${project_id}`)}
            variant="outlined"
          >
            GO TO PROJECT
          </Button>
          <Button
            onClick={() => mutate({ design_id: designId })}
            variant="outlined"
          >
            Reset
          </Button>
          <Button onClick={toggleModal} color="error">
            Cancel
          </Button>
        </div>
      }
      handleClose={toggleModal}
      open={isOpen}
    >
      {isLoading && (
        <div style={{ marginBottom: "30px" }}>
          <Loader />
        </div>
      )}
      <Alert severity="error">
        <div
          style={{ width: "500px" }}
          dangerouslySetInnerHTML={{ __html: t("lab.unlockDesign") }}
        />
      </Alert>
    </UiKitModal>
  );
};
export default ResetLabModal;
