import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, Typography } from "@mui/material";

import { getUser } from "services/webapp/user/endpoints";
import PageModal from "components/PageModal";

import ThemeSettings from "./components/ThemeSettings/ThemeSettings";
import LanguageSettings from "./components/LanguageSettings/LanguageSettings";
import classes from "./style.module.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SettingsPage: React.FunctionComponent = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUser({}).then((res) => {});
  }, []);

  return (
    <PageModal title="Settings" onClose={() => navigate(-1)}>
      <div className={classes.container}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab color="primary" label="Theme" {...a11yProps(0)} />
          <Tab color="primary" label="Languages" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ThemeSettings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LanguageSettings />
        </TabPanel>
      </div>
    </PageModal>
  );
};

export default SettingsPage;
