import { Box, styled } from "@mui/material";

export const LayersContainer = styled(Box)({
  minWidth: "250px",
  width: "250px",
  zIndex: 1,
});
export const SingleLayerContainer = styled("div")(
  (props: { soilColor: string; thickness: number }) => ({
    width: "100%",
    padding: "10px",
    borderBottom: "1px solid #ffffff",
    height: props.thickness + "px",
    backgroundColor: props.soilColor,
    overflowY: "auto",
  })
);
