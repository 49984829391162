import { Request } from "services/_base";
import { Service } from "services/types";
import {
  UpdataUserVariables,
  UpdateUserResponse,
  GetUserVariables,
  GetUserResponse,
} from "./types";

export const getUser: Service<GetUserVariables, GetUserResponse> = () => {
  return Request.get("users", {});
};

export const updateUserEmail: Service<
  UpdataUserVariables,
  UpdateUserResponse
> = ({ data }) => {
  return Request.put("user/update/email", data, {});
};
export const updateUseName: Service<{ data: { name: string } }, {}> = ({
  data,
}) => {
  return Request.put("user/update/name", data, {});
};

export const resetUserPassword: Service<
  { data: { password: string; confirmPassword: string; token: string } },
  { type: string }
> = ({ data }) => {
  return Request.put("/user/update-password/token", data, {});
};

export const updatePassword: Service<
  { data: { currentPassword: string; newPassword: string } },
  { type: boolean }
> = ({ data }) => {
  return Request.put("user/update/password", data, {});
};
