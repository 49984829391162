import React, { useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import useAuthStore from "context/auth-store";
import MapLayout from "layout/Map";
import MapProvider from "bootstrap/Map/MapProvider";

import RouterList from "./Routes";

const ProtectedRoute: React.FunctionComponent = () => {
  const accessToken = useAuthStore((state) => state.accessToken);

  useEffect(() => {}, [accessToken]);
  return accessToken ? <Outlet /> : <Navigate to="/" />;
};

const RouterMapProvider: React.FunctionComponent = () => {
  const protectedRouters = RouterList.filter(({ security }) => {
    return security === "protected";
  });

  const publicRouters = RouterList.filter(({ security }) => {
    return security === "public";
  });

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        {protectedRouters.map(({ Page, path, layout }) => {
          return (
            <Route
              path={path}
              key={path}
              element={
                layout ? (
                  <MapProvider>
                    <MapLayout>
                      <Page />
                    </MapLayout>
                  </MapProvider>
                ) : (
                  <Page />
                )
              }
            />
          );
        })}
      </Route>

      {publicRouters.map(({ Page, path }) => {
        return <Route path={path} key={path} element={<Page />} />;
      })}
    </Routes>
  );
};

export default RouterMapProvider;
