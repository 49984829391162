import { Request } from "services/_base";
import { IService, Service } from "services/types";
import { GET_NOTIFICATONS_URL, PUT_NOTIFICATIONS_URL } from "./constants";
import {
  GetNotificationsServiceVariable,
  GetNotificationsServiceResponse,
  PutNotificationsVariable,
} from "./types";

export const getNotifications: IService<
  GetNotificationsServiceVariable,
  GetNotificationsServiceResponse
> = (params) => {
  return Request.get(GET_NOTIFICATONS_URL, params);
};

export const readNotification: Service<
  PutNotificationsVariable,
  GetNotificationsServiceResponse
> = (data) => {
  return Request.put(PUT_NOTIFICATIONS_URL, data, {});
};
