import { SoilProperties, SoilTypes } from "./soilProperties";

export class Layer {
  static Manual() {
    return [
      { apiName: SoilProperties.type, value: SoilTypes.Manual, name: "type" },
      {
        apiName: SoilProperties.thickness,
        value: 4.5,
        name: "thickness",
      },
      {
        apiName: SoilProperties.unitWeightUnsat,
        value: 18.0,
        name: "Unit Weight Unsat",
      },
      {
        apiName: SoilProperties.unitWeightSat,
        value: 19.0,
        name: "Unit Weight Sat",
      },
      {
        apiName: SoilProperties.unitSkinFriction,
        value: 100.0,
        name: "Skin Friction",
      },
      {
        apiName: SoilProperties.unitTipResistance,
        value: 1000.0,
        name: "Tip Resistance",
      },
    ];
  }
  static Cohesive() {
    return [
      { apiName: SoilProperties.type, value: SoilTypes.Cohesive, name: "type" },
      { apiName: SoilProperties.thickness, value: 4.5, name: "thickness" },
            {
        apiName: SoilProperties.unitWeightUnsat,
        value: 17.5,
        name: "Unit Weight Unsat",
      },
      {
        apiName: SoilProperties.unitWeightSat,
        value: 18.5,
        name: "Unit Weight Sat",
      },
      {
        apiName: SoilProperties.undrainedCohesion,
        value: 50.0,
        name: "Undrain Cohesion",
      },
      {
        apiName: SoilProperties.undrainedCohesionInc,
        value: 1.0,
        name: "Undrain Cohesion Inc",
      },
    ];
  }
  static Granular() {
    return [
      { apiName: SoilProperties.type, value: SoilTypes.Granular, name: "type" },
      { apiName: SoilProperties.thickness, value: 4.5, name: "thickness" },
      {
        apiName: SoilProperties.unitWeightUnsat,
        value: 18.5,
        name: "Unit Weight Unsat",
      },
      {
        apiName: SoilProperties.unitWeightSat,
        value: 19.5,
        name: "Unit Weight Sat",
      },
      {
        apiName: SoilProperties.angleOfShearingResistance,
        value: 32.0,
        name: "Angle Of Shearing",
      },
    ];
  }

  static WeakRock() {
    return [
      { apiName: SoilProperties.type, value: SoilTypes.WeakRock, name: "type" },
      { apiName: SoilProperties.thickness, value: 4.5, name: "thickness" },
      {
        apiName: SoilProperties.unitWeightUnsat,
        value: 19.5,
        name: "Unit Weight Unsat",
      },
      {
        apiName: SoilProperties.unitWeightSat,
        value: 20.55,
        name: "Unit Weight Sat",
      },
      {
        apiName: SoilProperties.uniaxialCompressiveStrength,
        value: 2000.0,
        name: "UCS",
        tooltip: "Uniaxial Compressive Strength",
      },
    ];
  }
  static WeakRockCustom() {
    return [
      {
        apiName: SoilProperties.type,
        value: SoilTypes.WeakRockCustom,
        name: "type",
      },
      { apiName: SoilProperties.thickness, value: 4.5, name: "thickness" },
      {
        apiName: SoilProperties.unitWeightUnsat,
        value: 20.0,
        name: "Unit Weight Unsat",
      },
      {
        apiName: SoilProperties.unitWeightSat,
        value: 21.0,
        name: "Unit Weight Sat",
      },
      {
        apiName: SoilProperties.uniaxialCompressiveStrength,
        value: 5000.0,
        name: "UCS",
        tooltip: "Uniaxial Compressive Strength",
      },
      {
        apiName: SoilProperties.correlationConstant,
        value: 0.55,
        name: "Correlation Factor",
      },
      {
        apiName: SoilProperties.unitTipResistance,
        value: 10000.0,
        name: "Tip Resistance",
      },
    ];
  }
  static RockTomLinson() {
    return [
      {
        apiName: SoilProperties.type,
        value: SoilTypes.RockTomlinson,
        name: "type",
      },
      { apiName: SoilProperties.thickness, value: 4.5, name: "thickness" },
      {
        apiName: SoilProperties.unitWeightUnsat,
        value: 21.0,
        name: "Unit Weight Unsat",
      },
      {
        apiName: SoilProperties.unitWeightSat,
        value: 22.0,
        name: "Unit Weight Sat",
      },
      {
        apiName: SoilProperties.uniaxialCompressiveStrength,
        value: 10000.0,
        name: "UCS",
        tooltip: "Uniaxial Compressive Strength",
      },
      {
        apiName: SoilProperties.rockQualityDesignation,
        value: 50.0,
        name: "RQD",
      },
    ];
  }
}
