export enum SoilProperties {
  type = "type",
  thickness = "thickness",
  unitSkinFriction = "unit_skin_friction",
  unitWeightUnsat = "unit_weight_unsat",
  unitWeightSat = "unit_weight_sat",
  uniaxialCompressiveStrength = "uniaxial_compressive_strength",
  angleOfShearingResistance = "angle_of_shearing_resistance",
  correlationConstant = "correlation_constant",
  unitTipResistance = "unit_tip_resistance",
  rockQualityDesignation = "rock_quality_designation",
  undrainedCohesion = "undrained_cohesion",
  undrainedCohesionInc = "undrained_cohesion_inc",
}
export enum SoilPropertiesUnit {
  type = "string",
  thickness = "m",
  unitSkinFriction = "kPa",
  unitWeightUnsat = "kN/m³",
  unitWeightSat = "kN/m³",
  uniaxialCompressiveStrength = "kPa",
  angleOfShearingResistance = "°",
  correlationConstant = "",
  unitTipResistance = "kPa",
  rockQualityDesignation = "%",
  undrainedCohesion = "kPa",
  undrainedCohesionInc = "kPa/m",
}
export enum SoilTypes {
  Manual = "Manual",
  Cohesive = "Cohesive",
  Granular = "Granular",
  WeakRock = "WeakRock",
  WeakRockCustom = "WeakRockCustom",
  RockTomlinson = "RockGeneral",
}
