import React from "react";

import {
  UnitRulerComponent,
  UnitRulerLine,
  UnitRulerValue,
} from "./Ruler.styled";
import { UnitRulerProps } from "./types";

const UnitRuler: React.FunctionComponent<UnitRulerProps> = ({ number }) => {
  return (
    <UnitRulerComponent>
      {number % 5 === 0 && <UnitRulerValue>{number}</UnitRulerValue>}
      <UnitRulerLine width={number % 5 === 0 ? "100%" : "20px"} />
    </UnitRulerComponent>
  );
};

export default UnitRuler;
