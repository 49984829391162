import React, { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { Box, Typography } from "@mui/material";

import LabModal from "containers/Lab/components/LabModal/LabModal/LabModal";
import LayerRow from "containers/Lab/components/LabModal/ModalRow/ModalRow";
import Button from "components/Inputs/Button/Button";
import { soils } from "app/enums/soils";
import { Layer } from "models/layers";
import { FieldsType, ProfileType } from "./types";
import { updateLayers } from "services/webapp/designs/endpoints";
import { useSearchParams } from "react-router-dom";
import useLabStore from "context/lab-store";
import TextField from "components/Inputs/TextField";

const LayerModal: React.FunctionComponent = () => {
  const { layers, profile, setLabData, isAnalysis } = useLabStore();
  let [searchParams] = useSearchParams();

  const saveProfile = (profileValue: ProfileType) => {
    setLabData("profile", { ...profile, ...profileValue });
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;

    const items = [...layers];
    const [reOrderedItem] = items.splice(source.index, 1);

    items.splice(destination.index, 0, reOrderedItem);

    setLabData("layers", items);
  };

  const save = (id: number, row: FieldsType) => {
    const newLayers = [...layers];

    if (id > -1) {
      newLayers[id] = row;
      setLabData("layers", newLayers);
      return;
    }
    setLabData("layers", [...newLayers, row]);
  };

  const deleteItem = (index: number) => {
    const newLayers = [...layers];
    newLayers.splice(index, 1);
    setLabData("layers", [...newLayers]);
  };

  const createLayer = (soilType: string) => {
    let layer: FieldsType = [];
    if (soilType === "manual") {
      layer = Layer.Manual();
    }
    if (soilType === "granular") {
      layer = Layer.Granular();
    }
    if (soilType === "cohesive") {
      layer = Layer.Cohesive();
    }
    if (soilType === "weak rock") {
      layer = Layer.WeakRock();
    }
    if (soilType === "weakrock custom") {
      layer = Layer.WeakRockCustom();
    }
    if (soilType === "rock general") {
      layer = Layer.RockTomLinson();
    }
    setLabData("layers", [...layers, layer]);
  };

  useEffect(() => {
    updateLayers({
      data: {
        layers: JSON.stringify({ layers: layers, profile: profile }),
        designId: searchParams.get("d") || "",
      },
    });
  }, [profile, layers, searchParams]);

  return (
    <LabModal
      header={
        <>
          <TextField
            label="Ground Water Level"
            size="small"
            name="gwt_level"
            type="number"
            defaultValue={profile?.gwt_level}
            disabled={isAnalysis}
            onChange={(e) => {
              saveProfile({ gwt_level: Number(e.currentTarget.value) });
            }}
          />
          <TextField
            label="Borehole Level,m"
            size="small"
            name="borehole_level"
            type="number"
            disabled={isAnalysis}
            defaultValue={profile?.borehole_level}
            onChange={(e) =>
              saveProfile({ borehole_level: Number(e.currentTarget.value) })
            }
          />
        </>
      }
      actions={soils.map((soil) => (
        <Button
          key={soil.name}
          padding="12px"
          width={"150px"}
          variant="contained"
          disabled={isAnalysis}
          bgColor={soil.color}
          onClick={() => createLayer(soil.name)}
        >
          <Typography fontSize={"12px"}> {soil.name}</Typography>
        </Button>
      ))}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="lab-layers">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {layers &&
                layers.map((l, index) => {
                  return (
                    <LayerRow
                      key={
                        String(index) +
                        l.find((l) => l.apiName === "type")?.value
                      }
                      id={index}
                      index={index}
                      columns={l}
                      save={save}
                      deleteItem={deleteItem}
                    />
                  );
                })}
              {provided.placeholder}
              {!layers && <div>Not Found</div>}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </LabModal>
  );
};

export default LayerModal;
