import * as yup from "yup";

export const addUserSchema = yup
  .object({
    email: yup.string().required(),
  })
  .required();

  export type AddUserForm = {
    email:string
  }