import { SingleThemeProps } from "./types";
import { SingleThemeContainer } from "./Theme.styled";

const SingleTheme: React.FunctionComponent<SingleThemeProps> = ({
  theme,
  isSelected,
  handleTheme,
}) => {
  return (
    <SingleThemeContainer onClick={handleTheme} isSelected={isSelected}>
      {theme}
    </SingleThemeContainer>
  );
};
export default SingleTheme;
