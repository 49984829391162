import { Service } from "services/types";
import { Request } from "services/_base";
import {
  GetReportsVariables,
  GetReportsResponse,
  PostResetReport,
  PostResetReportResponse,
} from "./types";

export const getReport: Service<GetReportsVariables, GetReportsResponse> = ({
  params,
}) => {
  return Request.get(`reports/${params.designId}`, {});
};

export const deleteReport: Service<
  PostResetReport,
  PostResetReportResponse
> = ({ design_id }) => {
  return Request.post(`/report/reset-analysis`, { design_id });
};
