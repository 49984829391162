import SingleTheme from "./SingleTheme";

import BlackThemeSVG from "app/assets/themes/BlackTheme";
import LightThemeSVG from "app/assets/themes/LightTheme";
import useThemeStore from "context/theme-store";

const ThemeSettings: React.FunctionComponent = () => {
  const { setThemeType, themeType, setThemeSelected } = useThemeStore();

  const handleTheme = (theme: string) => {
    setThemeType(theme);
    setThemeSelected();
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        gap: "20px",
      }}
    >
      <SingleTheme
        theme={<BlackThemeSVG />}
        isSelected={themeType === "dark"}
        handleTheme={() => handleTheme("dark")}
      />
      <SingleTheme
        theme={<LightThemeSVG />}
        isSelected={themeType === "light"}
        handleTheme={() => handleTheme("light")}
      />
    </div>
  );
};

export default ThemeSettings;
