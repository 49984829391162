/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";
import useToggle from "hooks/Toggle/useToogle";
import { toast } from "react-toastify";
import useUserStore from "context/user-store";
import { useMutation } from "@tanstack/react-query";
import { updateUseName } from "services/webapp/user/endpoints";

import classes from "./style.module.css";
import Input from "components/UiKit/Input/Input";

const UpdateName: React.FunctionComponent = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { user, setUser } = useUserStore();
  const [editModal, toggleEditModal] = useToggle();
  const { mutate, isSuccess } = useMutation(updateUseName);

  const submit = () => {
    mutate({ data: { name: ref.current?.value || user.name } });
  };

  useEffect(() => {
    if (isSuccess) {
      toast("Name has been updated successfully", { type: "success" });
      setUser({ ...user, name: ref.current?.value || "" });
    }
  }, [isSuccess, setUser]);

  return (
    <div className={classes.single_input__container}>
      <div className={classes.single_input}>
        <div className={classes.single_input__title}>{"Name"}</div>
        <div className={classes.single_input__value}>{user.name}</div>

        <button className={classes.btn} onClick={toggleEditModal}>
          Edit
        </button>
      </div>
      {editModal && (
        <div className={classes.edit_modal}>
          <Input
            type="text"
            className={classes.input}
            placeholder={"new value"}
            defaultValue={user.name}
            ref={ref}
          />
          <button className={classes.btn} onClick={submit}>
            Update
          </button>
        </div>
      )}
    </div>
  );
};
export default UpdateName;
