//@ts-nocheck
import styled from "@emotion/styled";

export const PreviewContainer = styled("div")(
  (props: { fullScreen: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "50%",
    padding: props.fullScreen ? "" : "10px",
    maxHeight: "100%",
    border: props.fullScreen ? "inherit" : "1px #cccccc solid",
    borderRadius: "4px",
    overflowY: props.fullScreen ? "inherit" : "scroll",
  })
);
