import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { LanguageStoreType } from "./types";

const useLanguageStore = create<LanguageStoreType>()(
  persist(
    (set) => ({
      language: "en",
      setLanguage: (lang) => set(() => ({ language: lang })),
    }),
    {
      name: "languageStore",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useLanguageStore;
