import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import UsersPage from "containers/Account/Users/UsersPage";
import PageModal from "components/PageModal";
import { getUser } from "services/webapp/user/endpoints";

import PlanDetailPage from "./Plan";
import PaymentPage from "./Payment";
import ProfilePage from "./Profile";
import classes from "./style.module.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return <>{value === index && children}</>;
}

const AccountPage: React.FunctionComponent = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUser({}).then((res) => {});
  }, []);

  return (
    <PageModal title="Account" onClose={() => navigate(-1)}>
      <div className={classes.container}>
        <div className={classes.tabs}>
          <button
            className={value === 0 ? classes.selected : ""}
            onClick={() => handleChange(0)}
          >
            Account
          </button>
          <button
            className={value === 1 ? classes.selected : ""}
            onClick={() => handleChange(1)}
          >
            Users
          </button>
          <button
            className={value === 2 ? classes.selected : ""}
            onClick={() => handleChange(2)}
          >
            Billings
          </button>
          <button
            className={value === 3 ? classes.selected : ""}
            onClick={() => handleChange(3)}
          >
            Licenses
          </button>
        </div>
        <div style={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <ProfilePage />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UsersPage />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PaymentPage />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PlanDetailPage />
          </TabPanel>
        </div>
      </div>
    </PageModal>
  );
};

export default AccountPage;
