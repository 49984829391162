import { Request } from "services/_base";
import { Service } from "services/types";

import { LOGIN_URL, REGISTER_URL, RESET_PASSWORD_URL } from "./constants";
import {
  LoginServiceResponse,
  LoginServiceVariables,
  RegisterServiceVariables,
} from "./types";

export const login: Service<LoginServiceVariables, LoginServiceResponse> = ({
  data,
}) => {
  return Request.get(LOGIN_URL, data);
};
export const register: Service<
  RegisterServiceVariables,
  LoginServiceResponse
> = ({ data }) => {
  return Request.post(REGISTER_URL, data, {});
};

export const resetPassword: Service<{}, {}> = (data) => {
  return Request.post(RESET_PASSWORD_URL, data, {});
};
