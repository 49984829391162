import React from "react";

import { PreviewProps } from "./types";
import { PreviewContainer } from "./Preview.styled";
import useLabStore from "context/lab-store";
import SinglePreview from "./SinglePreview";
import Ruler from "containers/Lab/steps/Preview/components/Ruler/Ruler";
import classes from "./preview.module.scss";

const Preview: React.FunctionComponent<PreviewProps> = () => {
  const layers = useLabStore((state) => state.layers);
  const profile = useLabStore((state) => state.profile);

  const totalLayersThickness =
    layers
      ?.map((layer) => layer.find((l) => l.apiName === "thickness")?.value)
      ?.reduce((total = 0, next) => Number(total) + Number(next), 0) || 0;

  return (
    <PreviewContainer>
      {layers.length > 0 &&
        layers.map((layer) => <SinglePreview layer={layer} />)}
      <div className={classes.ruler_container}>
        <Ruler
          max={Number(profile?.borehole_level)}
          min={Number(profile?.borehole_level) - Number(totalLayersThickness)}
          height={0}
        />
      </div>
    </PreviewContainer>
  );
};
export default Preview;
