import React from "react";
import { SingleLayerContainer } from "./Layers.styled";
import { SingleLayerPreviewProps } from "./types";
import { SoilProperties } from "models/soilProperties";
import { soilsColor } from "app/enums/soils";
import { Tooltip, Typography } from "@mui/material";
import { AdsClick } from "@mui/icons-material";

const SingleLayer: React.FunctionComponent<SingleLayerPreviewProps> = ({
  layer,
}) => {
  const thickness =
    (layer.find((arr) => arr.name === SoilProperties.thickness)
      ?.value as number) || 0;
  const soilType = layer.find((arr) => arr.name === SoilProperties.type)
    ?.value as string;

  const soilColor = soilsColor.find(
    (soil) => soil.name === soilType.toLowerCase()
  )?.color as string;

  return (
    <Tooltip
      title={layer.map((l) => (
        <div>
          <Typography fontSize={"14px"}>
            {l.name}={l.value}
          </Typography>
        </div>
      ))}
      placement="right"
    >
      <SingleLayerContainer soilColor={soilColor} thickness={thickness * 25}>
        <Typography sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
          {<AdsClick />}
          {layer.find((l) => l.apiName === "type")?.value}
        </Typography>
        {layer
          .filter((l) => l.apiName !== "type")
          .map((l) => (
            <div style={{ display: "flex" }}>
              <Typography fontSize={9}>{l.name}</Typography>
              <Typography fontSize={9}>{l.value}</Typography>
            </div>
          ))}
      </SingleLayerContainer>
    </Tooltip>
  );
};

export default SingleLayer;
