import { useNavigate } from "react-router-dom";

import { LinkProps } from "./types";
import classes from "./Link.module.scss";

const Link: React.FunctionComponent<LinkProps> = ({
  children,
  to,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className={classes.link}
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }
        navigate(to ? to : "");
      }}
    >
      {children}
    </button>
  );
};

export default Link;
