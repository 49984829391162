export const LightTheme = {
  backgroundColor: "#F0F2F2",
  layout: {
    content: {
      bgColor: "#ffffff",
    },
  },
  header: {
    bgColor: "#ffffff",
  },
  sidebar: {
    bgColor: "#ffffff",
    logo: {
      bgColor: "#ffffff",
    },
    link: {
      color: "#101010",
    },
  },
  font: {
    color: "#000000",
  },
  table: {
    bg: "initial",
    cell: {
      color: "initial",
    },
  },
};
