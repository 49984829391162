import React from "react";
import { useNavigate } from "react-router-dom";
import { Share } from "@mui/icons-material";

import { formattedDateWithGMT } from "app/utils/date/toFormatDate";
import { TableCell, TableRow } from "components/Table";
import IconButton from "components/Inputs/IconButton";
import useUserStore from "context/user-store";
import useProjectStore from "context/project-store";
import useToggle from "hooks/Toggle/useToogle";

import classes from "./style.module.css";
import ProjectRowMenu from "./ProjectRowMenu";
import SharedModal from "../SharedModal/SharedModa";
import { ProjectTableRowProps } from "./types";

const ProjectTableRow: React.FunctionComponent<ProjectTableRowProps> = ({
  project,
  getProjects,
}) => {
  const [isShareModalToggle, setShareModalToggle] = useToggle();
  const navigate = useNavigate();
  const userID = useUserStore((state) => state.user.id);

  return (
    <>
      <TableRow
        onClick={() => {
          const coordinate = {
            latitude: Number(project?.location?.lat),
            longitude: Number(project?.location?.lng),
          };
          useProjectStore.getState().setLocation({
            coordinate: coordinate,
            name: project?.name,
            country: project?.location?.country || "",
          });
          navigate("/map");
          navigate(`/project?p=${project.id}`);
        }}
        key={project.id}
      >
        <TableCell>{project.name}</TableCell>
        <TableCell>{project?.location?.displayName?.length > 25 ? `${project?.location?.displayName?.slice(0, 22)}...` : project?.location?.displayName}</TableCell>
        <TableCell>{formattedDateWithGMT(project.created_on)}</TableCell>
        <TableCell>
          {project.user_id !== userID ? (
            <span className={classes.tag}>Shared With You</span>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell noPadding>
          <div style={{ display: "flex", gap: "12px" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setShareModalToggle();
              }}
              size="small"
            >
              <Share />
            </IconButton>

            <ProjectRowMenu getProjects={getProjects} project={project} />
          </div>
        </TableCell>
      </TableRow>
      {isShareModalToggle && (
        <SharedModal
          setShareModalToggle={setShareModalToggle}
          project={project}
        />
      )}
    </>
  );
};
export default ProjectTableRow;
