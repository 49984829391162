import useUserStore from "context/user-store";
import classes from "./style.module.css";
const UserRole: React.FunctionComponent = () => {
  const { user } = useUserStore();
  return (
    <div className={classes.single_input__container}>
      <div className={classes.single_input}>
        <div className={classes.single_input__title}>{"Role"}</div>
        <div className={classes.single_input__value}>{user.auth}</div>
      </div>
    </div>
  );
};
export default UserRole;
