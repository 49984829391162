import React from "react";

import { FormContainer } from "./Form.styled";
import { FormProps } from "./types";

const Form: React.FunctionComponent<FormProps> = ({ children, width }) => {
  return <FormContainer style={{ width: width }}>{children}</FormContainer>;
};

export default Form;
