import React from "react";
import { Button } from "@mui/material";

import { AnalysisResultModalProps } from "./types";
import AllowableChart from "./Charts/AllowableChart";
import UltimateChart from "./Charts/UltimateChart";

const AnalysisResultModal: React.FunctionComponent<
  AnalysisResultModalProps
> = ({ analysisResult, pileId }) => {
  //const [chartType, setChartType] = useState("ultimate");
  let chartType = "ultimate";
  // const handleChange = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string
  // ) => {
  //   if (newAlignment === null) return;
  //   setChartType(newAlignment);
  // };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        paddingRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {!analysisResult && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            Not Found
            <Button variant="contained">Analysis Start</Button>
          </div>
        )}
        {analysisResult && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ width: "100%" }}>
              {chartType === "allowable" && (
                <AllowableChart
                  analysisResult={analysisResult}
                  pileId={pileId}
                />
              )}
              {chartType === "ultimate" && (
                <UltimateChart
                  analysisResult={analysisResult}
                  pileId={pileId}
                />
              )}
            </div>

            {/* <ToggleButtonGroup
              color="primary"
              value={chartType}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value="allowable">Allowable</ToggleButton>
              <ToggleButton value="ultimate">Ultimate</ToggleButton>
            </ToggleButtonGroup> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalysisResultModal;
