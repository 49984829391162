import { Box, styled } from "@mui/material";

export const SingleAnalysisResultContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
});

export const SingleAnalysisResultHeader = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  padding: "20px",
});
export const SingleAnalysisResultContent = styled(Box)({
  position: "relative",
  display: "flex",
  padding: "0px 12px",
  height: "calc(100% - 90px)",
});

export const PileListContainer = styled(Box)({
  minWidth: "fit-content",
  padding: "4px",
});
