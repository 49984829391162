import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import useAuthStore from "context/auth-store";

import { GuardProviderProps } from "./types";

const Guard: React.FunctionComponent<GuardProviderProps> = ({ children }) => {
  const clearAccessToken = useAuthStore((state) => state.clearAccessToken);
  const accessToken = useAuthStore((state) => state.accessToken);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken !== null) {
      if (
        ["/", "/login", "/signup", "/forgot-password"].includes(
          location.pathname
        )
      ) {
        navigate("/map");
      }
    }
  }, [location, accessToken, clearAccessToken, navigate]);

  return <>{children}</>;
};

export default Guard;
