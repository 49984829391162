import { create } from "zustand";

import { DesignsStoreType } from "./types";

const useDesignsStore = create<DesignsStoreType>()((set) => ({
  designCount: 0,
  setDesignCount: (designCount) => set(() => ({ designCount: designCount })),
}));

export default useDesignsStore;
