import React from "react";
import { useNavigate } from "react-router-dom";
import { ExitToApp, LockReset } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import { LabLayoutHeaderProps } from "./types";
import useLabStore from "context/lab-store";
import ResetLabModal from "../ResetLab/ResetLabModal";
import useToggle from "hooks/Toggle/useToogle";
import DesignTitle from "./DesignTitle";

const LabLayoutHeader: React.FunctionComponent<LabLayoutHeaderProps> = () => {
  const navigate = useNavigate();
  const [toggle, toggleModal] = useToggle();
  const { customTheme } = useTheme();
  const { isAnalysis, project_id } = useLabStore();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "0 auto",
        padding: "4px 30px",
        alignItems: "center",
        backgroundColor: customTheme.header.bgColor,
        zIndex: "1000",
      }}
    >
      <div style={{ minWidth: "fit-content", color: customTheme.font.color }}>
        <DesignTitle />
      </div>
      <div style={{ marginLeft: "20px" }}>
        {isAnalysis && (
          <IconButton onClick={() => toggleModal()}>
            <LockReset fontSize={"large"} color="primary" />
          </IconButton>
        )}
      </div>
      <div style={{ width: "100%" }}></div>
      <div style={{ display: "flex", gap: "8px" }}>
        <IconButton
          onClick={() => navigate(`/project?p=${project_id}`)}
          color="primary"
        >
          <ExitToApp />
        </IconButton>
      </div>
      <ResetLabModal toggleModal={toggleModal} isOpen={toggle} />
    </div>
  );
};

export default LabLayoutHeader;
