import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem, Menu, Chip } from "@mui/material";
import { Delete, MoreVert, CopyAllOutlined, Edit } from "@mui/icons-material";
import { DesignTableRowProps } from "./types";
import {
  deleteDesign,
  duplicateDesign,
  updateDesignName,
} from "services/webapp/designs/endpoints";

import IconButton from "components/Inputs/IconButton";
import { formattedDateWithGMT } from "app/utils/date/toFormatDate";
import useToggle from "hooks/Toggle/useToogle";
import classes from "./style.module.css";
import { useMutation } from "@tanstack/react-query";
import useUserStore from "context/user-store";
import PileImg from "app/assets/icon/pile-white.png";
import PileImgHover from "app/assets/icon/pile-black.png";
import { TableCell, TableRow } from "components/Table";

const DesignTableRow: React.FunctionComponent<DesignTableRowProps> = ({
  design,
  refetch,
}) => {
  const userID = useUserStore((state) => state.user.id);
  const ref = useRef<HTMLInputElement>(null);
  const [toggle, setToggle] = useToggle();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const toggleMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [design_name, setDesignName] = useState(design.design_name);
  const { mutate } = useMutation(updateDesignName);
  const deleteDesignHandler = async (designId: string) => {
    const res = await deleteDesign({ data: { designId: designId } });
    if (res.data.type) {
      refetch();
    }
  };
  const duplicateDesignHandler = async (designId: string) => {
    const res = await duplicateDesign({ params: { designId: designId } });
    if (res.data.type) {
      refetch();
    }
  };

  const changeNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setDesignName(e.target.value);
  };

  useEffect(() => {
    mutate({
      data: {
        designName: design_name,
        designId: design.id,
      },
    });
  }, [design.id, design_name, mutate]);

  useEffect(() => {
    ref.current?.focus();
  }, [toggle]);

  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <TableRow
        onClick={() => navigate(`/lab?d=${design.id}`)}
        key={design.id}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <TableCell>
          {toggle ? (
            <input
              ref={ref}
              onBlur={setToggle}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setToggle();
                }
              }}
              onChange={changeNameHandler}
              className={classes.design_name_input}
              defaultValue={design_name}
            />
          ) : (
            design_name
          )}
        </TableCell>
        <TableCell>{formattedDateWithGMT(design.created_on)}</TableCell>
        <TableCell>
          {design.is_analysis ? (
            <Chip label="Analysis Done" size="small" />
          ) : (
            <Chip label="In progress" variant="outlined" size="small" />
          )}
        </TableCell>
        <TableCell>
          {design.user_id === userID ? (
            <Chip label="Owner" color="success" size="small" />
          ) : (
            <Chip label="Read Only" variant="filled" size="small" />
          )}
        </TableCell>
        <TableCell noPadding>
          <div className={classes.flex}>
            <IconButton
              onClick={() => navigate(`/lab?d=${design.id}`)}
              size="small"
            >
              <img
                src={isHovering ? PileImgHover : PileImg}
                alt="Drill icon"
                style={{ width: "24px", height: "24px" }}
              />
            </IconButton>

            <IconButton
              aria-expanded={toggleMenu ? "true" : undefined}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
              size="small"
            >
              <MoreVert />
            </IconButton>
            <Menu
              sx={{ zIndex: 999999999 }}
              anchorEl={anchorEl}
              open={toggleMenu}
              onClick={(e) => e.stopPropagation()}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                sx={{ fontSize: 14, gap: "4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteDesignHandler(design.id);
                  setAnchorEl(null);
                }}
              >
                <Delete fontSize="small" />
                Delete
              </MenuItem>
              <MenuItem
                sx={{ fontSize: 14, gap: "4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  duplicateDesignHandler(design.id);
                  setAnchorEl(null);
                }}
              >
                <CopyAllOutlined fontSize="small" />
                Duplicate
              </MenuItem>
              <MenuItem
                sx={{ fontSize: 14, gap: "4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setToggle();
                  setAnchorEl(null);
                }}
              >
                <Edit fontSize="small" />
                Rename
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
export default DesignTableRow;
