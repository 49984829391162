import { defaultColors } from "styles/soils.colors";

export enum Soil {
  Manual = "manual",
  Cohesive = "cohesive",
  Granular = "granukar",
  WeakRock = "weakrock",
  WeakRockCustom = "weakrockcustom",
  RockTomlinson = "rockgeneral",
}

export const soilsColor = [
  {
    name: "manual",
    color: defaultColors.gray.default,
  },

  {
    name: "granular",
    color: defaultColors.yellow.aztecGold,
  },
  {
    name: "cohesive",
    color: defaultColors.brown.sealBrown,
  },
  {
    name: "weakrock",
    color: defaultColors.yellow.metallicBronze,
  },
  {
    name: "weakrockcustom",
    color: defaultColors.yellow.darkBronze,
  },
  {
    name: "rockgeneral",
    color: defaultColors.orange.ochre,
  },
];

export const soils = [
  {
    name: "manual",
    color: defaultColors.gray.default,
  },

  {
    name: "granular",
    color: defaultColors.yellow.aztecGold,
  },
  {
    name: "cohesive",
    color: defaultColors.brown.sealBrown,
  },
  {
    name: "weak rock",
    color: defaultColors.yellow.metallicBronze,
  },
  {
    name: "weakrock custom",
    color: defaultColors.yellow.darkBronze,
  },
  {
    name: "rock general",
    color: defaultColors.orange.ochre,
  },
];
