import React from "react";
import { IconButton } from "@mui/material";
import { FormatListBulleted, Add } from "@mui/icons-material/";
import { useLocation, useNavigate } from "react-router-dom";
import SearchLocation from "../Locations/SearchLocation";
import useProjectStore from "context/project-store";
import classes from "./Header.module.scss";
import CreatePrtojectModal from "../CreateProjectModal";
import useToggle from "hooks/Toggle/useToogle";

const SearchArea: React.FunctionComponent = () => {
  const { setLocation } = useProjectStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useToggle();

  return (
    <>
      <div className={classes.searchArea}>
        <SearchLocation
          onSelectLocation={(e) => {
            setLocation(e);
            navigate("/project/create");
          }}
        />

        <IconButton
          sx={{
            height: "min-content",
            backgroundColor: "orange",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgb(255,128,0)",
            },
          }}
          onClick={() => setOpen()}
        >
          <Add />
        </IconButton>

        <IconButton
          sx={{
            height: "min-content",
            backgroundColor: "orange",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgb(255,128,0)",
            },
          }}
          onClick={() =>
            navigate(
              location.pathname === "/projects" ? "/map" : "/projects?page=1"
            )
          }
        >
          <FormatListBulleted />
        </IconButton>
        <CreatePrtojectModal open={open} setOpen={setOpen} />
      </div>
    </>
  );
};

export default SearchArea;
