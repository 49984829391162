import { TextField } from "@mui/material";
import { useEffect, useState, useRef, KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";
import { CreateProjectProps } from "./types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { creataProject } from "services/webapp/projects/endpoints";
import useProjectStore from "context/project-store";
import Button from "components/UiKit/Button/Button";
import classes from "./style.module.css";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const CreateProject: React.FunctionComponent<CreateProjectProps> = ({
  location,
}) => {
  const { t } = useTranslation();
  const locationJSON = JSON.stringify(location);
  console.log(location);
  const queryClient = useQueryClient();
  const { projectCount } = useProjectStore();
  const [projectName, setProjectName] = useState<string>(
    `Project ${projectCount + 1}`
  );

  const { mutate: createProjectMutation, isSuccess } = useMutation({
    mutationFn: creataProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["last_projects"] });
      queryClient.invalidateQueries({ queryKey: ["projects_map"] });
    },
  });
  const navigate = useNavigate();
  const projectNameTextField = useRef<HTMLInputElement>(null);

  const createProject = async () => {
    createProjectMutation({
      data: { location: locationJSON, projectName: projectName },
    });
  };
  const { setLocation } = useProjectStore();
  const cancelSelection = () => {
    navigate("/map");
    setLocation(null);
  };

  const onKeyDownEnterEscape = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      createProject();
    }
    if (e.key === "Escape") {
      cancelSelection();
    }
  };

  useEffect(() => {
    if (!isSuccess) return;
    navigate(`/projects`);
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (projectNameTextField.current) {
      projectNameTextField.current.focus();
      projectNameTextField.current.select();
    }
  }, []);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classes.createProjectModal__container}
    >
      <div className={classes.createProjectModal__inputs}>
        <TextField
          fullWidth
          size="small"
          placeholder="Enter project name"
          onChange={(e) => setProjectName(e.currentTarget.value)}
          value={projectName}
          onKeyDown={onKeyDownEnterEscape}
          inputRef={projectNameTextField}
        />
        <div className={classes.createProjectModal__location}>
          at <b>{location.displayName}</b>
        </div>
        <Button variant="contained" onClick={() => createProject()}>
          {t("dashboard.quickStart.createProject")}
        </Button>
        <IconButton onClick={() => cancelSelection()}>
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

export default CreateProject;
