import React, { useCallback, useEffect, useState } from "react";

import useToggle from "hooks/Toggle/useToogle";
import Axis from "./components/Axis/Axis";
import LayersPreview from "./components/Layers/LayersPreview";
import { PreviewContainer } from "./components/Container/Container.styled";
import Ruler from "./components/Ruler/Ruler";
import PilesPreview from "./components/Pile/PilesPreview";

import useLabStore from "context/lab-store";
import { rangeInt, rangeInteger } from "app/utils/math/range";
import { getArrayIndex } from "app/helper/getArrayIndex";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import SingleAnalysisResult from "./components/SingleAnalysisResult/SingleAnalysisResult";

import { AnalysisResultDto } from "./components/AnalysisCharts/types";
import MultipleAllowableChart from "./components/AnalysisCharts/Charts/MultipleChart";

const PreviewStep: React.FunctionComponent = () => {
  const { layers, piles, profile, reports, isAnalysis } = useLabStore();
  const [analysisResult, setAnalysisResult] =
    useState<Array<AnalysisResultDto>>();
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [toggleDrawer, setToggleDrawer] = useToggle();
  const [selectedPileId, setSelectedPileId] = useState<number>(0);
  const [previewInit, setPreviewInit] = useState({
    startRuler: 0,
    endRuler: 0,
  });

  useEffect(() => {
    setAnalysisResult(reports);
  }, [reports]);

  const handleAnalysis = (pileId: number) => {
    setSelectedPileId(pileId);
    setToggleDrawer();
  };

  const checkPreview = useCallback(() => {
    const boreholeLevel = Number(profile?.borehole_level);
    let layerWidth = 0;
    layers.forEach((layer) => {
      const value =
        layer.find((property) => property.apiName === "thickness")?.value || 0;
      layerWidth = layerWidth + Number(value);
    });
    const layersDepth = boreholeLevel - layerWidth;
    const maxPileCutOffLevel = Math.max(
      ...piles.map((pile) => pile.cutoff_level)
    );
    const maxPileDepth = Math.min(
      ...piles.map((pile) => pile.cutoff_level - pile.length)
    );
    const startRuler =
      maxPileCutOffLevel > boreholeLevel ? maxPileCutOffLevel : boreholeLevel;

    const endRuler = layersDepth > maxPileDepth ? maxPileDepth : layersDepth;

    const ruler = rangeInteger(endRuler, startRuler);
    const range = getArrayIndex(ruler, [0, ruler.length - 1]);

    setPreviewInit({ startRuler: range[1], endRuler: range[0] });
  }, [layers, piles, profile?.borehole_level]);

  useEffect(() => {
    checkPreview();
  }, [checkPreview]);
  const handleChange = (e: SelectChangeEvent<number>) => {
    setSelectedGroup(Number(e.target.value));
  };

  return (
    <>
      <PreviewContainer>
        <Axis />
        <span
          style={{
            position: "absolute",
            top: "-3px",
            fontSize: "14px",
            color: "rgba(70,70,70)",
            fontWeight: "600",
          }}
        >
          Elevation, m
        </span>
        <Ruler
          max={previewInit?.startRuler}
          min={previewInit?.endRuler}
          height={previewInit.startRuler - previewInit.endRuler}
        />
        {layers && (
          <LayersPreview
            layers={layers}
            elevation={previewInit?.startRuler}
            boreholeLevel={profile?.borehole_level}
          />
        )}
        {piles && (
          <PilesPreview
            piles={
              isAnalysis
                ? piles.slice((selectedGroup - 1) * 4, selectedGroup * 4)
                : piles
            }
            elevation={previewInit?.startRuler}
            isAnalysis={isAnalysis}
            onClick={handleAnalysis}
            height={previewInit.startRuler - previewInit.endRuler}
          />
        )}
        {isAnalysis && analysisResult && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "500px",
                position: "absolute",
                top: "20px",
                right: "0px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-23px",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "rgba(70,70,70)",
                }}
              >
                Allowable Bearing Capacity, kN
              </span>
              <MultipleAllowableChart
                analysisResult={analysisResult.slice(
                  (selectedGroup - 1) * 4,
                  selectedGroup * 4
                )}
                piles={piles.slice((selectedGroup - 1) * 4, selectedGroup * 4)}
              />
            </div>
            <div
              style={{
                minWidth: "500px",
              }}
            ></div>
          </>
        )}
      </PreviewContainer>
      {isAnalysis && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "#ffffff",
            left: "40px",
            top: "90px",
          }}
        >
          <FormControl>
            <InputLabel>Group</InputLabel>
            <Select
              size="small"
              value={selectedGroup}
              label="Age"
              onChange={handleChange}
            >
              {rangeInt(1, Math.ceil(Number(piles.length) / 4)).map((p) => (
                <MenuItem value={p}>Group {p}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <SingleAnalysisResult
        selectedPileId={selectedPileId}
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
      />
    </>
  );
};

export default PreviewStep;
