import { SoilProperties } from "models/soilProperties";

const SoilLayersValidations = {
  type: { max: 200, min: 0.5 },
  thickness: { max: 100, min: 0.5 },
  unit_skin_friction: { max: 1 / 0, min: 0 },
  unit_weight_unsat: { max: 1 / 0, min: 11 },
  unit_weight_sat: { max: 28, min: 11 },
  uniaxial_compressive_strength: { max: 1 / 0, min: 0.1 },
  angle_of_shearing_resistance: { max: 45, min: 5 },
  correlation_constant: { max: 1, min: 0 },
  unit_tip_resistance: { max: 1 / 0, min: 0 },
  rock_quality_designation: { max: 100, min: 0 },
  undrained_cohesion: { max: 1 / 0, min: 0 },
  undrained_cohesion_inc: { max: 1 / 0, min: 0 },
};

export const validation = (property: SoilProperties, value: number) => {
  const validations = SoilLayersValidations[property];
  const isError = value > validations.max || value < validations.min;
  if (isError) {
  }
  return {
    error: isError,
    helperText:
      isError &&
      (value > validations.max
        ? `value of ${property} cannot be greater than ${validations.max}`
        : `value of ${property} cannot be less than ${validations.min}`),
  };
};

export const validation2 = (property: string, value: number) => {
  const validations = SoilLayersValidations[property as SoilProperties];
  const isError = value > validations.max || value < validations.min;
  if (!isError) {
    return false;
  }
  return {
    error: isError,
    helperText:
      isError &&
      (value > validations.max
        ? `value of ${property} cannot be greater than ${validations.max}`
        : `value of ${property} cannot be less than ${validations.min}`),
  };
};
