import styled from "@emotion/styled";

export const PreviewContainer = styled("div")({
  position: "relative",
  width: "500px",
  height: "100%",
  border: "1px #cccccc solid",
  borderRadius: "4px",
  padding: "10px",
  overflowY: "scroll",
});
