import React, { useState } from "react";
import { PilesContainer } from "./components/Piles/Piles.styled";
import PilesModal from "./components/PilesModal";
import Preview from "./components/Preview/Preview";

const PilesStep: React.FunctionComponent = () => {
  const [selectedPile, setSelectedPile] = useState(0);

  return (
    <PilesContainer>
      <PilesModal
        handleSelectPile={setSelectedPile}
        selectedPile={selectedPile}
      />
      <Preview selectedPile={selectedPile} />
    </PilesContainer>
  );
};
export default PilesStep;
