import styled from "@emotion/styled";

export const ModalRowContainer = styled("div")({
  width: "100%",
  gap: "8px",
});

export const ModalRowContainerTitle = styled("div")({});
export const ModalRowContainerContent = styled("div")({
  display: "flex",
  gap: "8px",
  alignItems: "flex-start",
  width: "100%",
  padding: "10px 0px",
});
export const ModalRowContainerContentActions = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  justifyContent: "flex-end",
});
