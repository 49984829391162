import React from "react";
import { Box } from "@mui/material";

import Stepper from "components/Stepper/Stepper";

import { LabStepsProps } from "./types";
import useLabStore from "context/lab-store";

const LabStepsBar: React.FunctionComponent<LabStepsProps> = ({
  setSelectedStep,
  selectedStep,
}) => {
  const errors = useLabStore((state) => state.errors);
  const steps = [
    { label: "Soil Layers" },
    { label: "Piles" },
    { label: "Preview & Analysis" },
  ];
  const errorHandler = () => {
    const errorType = errors?.map((e) => e.type)[0];
    if (errorType === "layers" && selectedStep === 0) {
      return true;
    }
    if (errorType === "pile" && selectedStep === 1) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={{ width: "60%" }}>
      <Stepper
        disabledNext={errorHandler()}
        disabledBack={false}
        steps={steps}
        setSelectedStep={setSelectedStep}
      />
    </Box>
  );
};
export default LabStepsBar;
