/* eslint-disable react-hooks/exhaustive-deps */

import useToggle from "hooks/Toggle/useToogle";

import classes from "./style.module.css";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "services/webapp/user/endpoints";

const UpdatePassword: React.FunctionComponent = () => {
  const ref = useRef<HTMLInputElement>(null);
  const refNewPassword = useRef<HTMLInputElement>(null);
  const refConfirmPassword = useRef<HTMLInputElement>(null);
  const [editModal, toggleEditModal] = useToggle();
  const { mutate, isSuccess, data } = useMutation(updatePassword);
  const submit = () => {
    const currentPassword = ref.current?.value;
    const newPassword = refNewPassword.current?.value;
    const confirmPassword = refConfirmPassword.current?.value;

    if (newPassword === confirmPassword) {
      mutate({
        data: {
          currentPassword: currentPassword || "",
          newPassword: newPassword || "",
        },
      });
    } else {
      toast("Passwords do not match", { type: "error" });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (data.data.type) {
        toast("Password has been updated successfully", { type: "success" });
      } else {
        toast("Your current password is not correct", { type: "warning" });
      }
    }
  }, [isSuccess]);

  return (
    <div className={classes.single_input__container}>
      <div className={classes.single_input}>
        <div className={classes.single_input__title}>{"Password"}</div>
        <div className={classes.single_input__value}>**************</div>

        <button className={classes.btn} onClick={toggleEditModal}>
          Edit
        </button>
      </div>
      {editModal && (
        <div className={classes.edit_modal}>
          <input
            type="password"
            className={classes.input}
            placeholder={"Current Password"}
            ref={ref}
          />
          <input
            type="password"
            className={classes.input}
            placeholder={"New Password"}
            ref={refNewPassword}
          />
          <input
            type="password"
            className={classes.input}
            placeholder={"Confirm Password"}
            ref={refConfirmPassword}
          />
          <button className={classes.btn} onClick={submit}>
            Update
          </button>
        </div>
      )}
    </div>
  );
};
export default UpdatePassword;
