/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProjectStore from "context/project-store";

import Loader from "components/Feedback/Loader";
import MapContext from "./Context";
import classes from "./style.module.scss";

const Map = () => {
  const { mapRef, renderMap, map } = useContext(MapContext);
  const { setLocation, location } = useProjectStore();
  const navigate = useNavigate();
  const [currentAnnotation, setCurrentAnnotation] =
    useState<mapkit.Annotation | null>(null);
  const [currentRegion, setCurrentRegion] =
    useState<mapkit.CoordinateRegion | null>(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    renderMap();
  }, []);

  useEffect(() => {
    if (map) {
      map._allowWheelToZoom = true;
      map?.addEventListener("single-tap", (e) => {
        navigate("/project/create");
        setLoader(true);
        var domPoint = new DOMPoint(e.pointOnPage.x, e.pointOnPage.y);
        var coordinate = map.convertPointOnPageToCoordinate(domPoint);

        const geocoder = new mapkit.Geocoder({ language: "en-US" });

        geocoder.reverseLookup(coordinate, (error: any, data: any) => {
          const first = !error && data.results ? data.results[0] : null;
          setLocation({
            coordinate: {
              latitude: coordinate.latitude,
              longitude: coordinate.longitude,
            },
            name: first.name,
            country: first.country,
          });
          setLoader(false);
          // Zoom to the selected location but keep the current zoom levels if they are closer than 0.1
          const newRegion = new mapkit.CoordinateRegion(
            new mapkit.Coordinate(coordinate.latitude, coordinate.longitude),
            new mapkit.CoordinateSpan(
              Math.min(map.region.span.latitudeDelta, 0.1),
              Math.min(map.region.span.longitudeDelta, 0.1)
            )
          );
          map.setRegionAnimated(newRegion, true);
        });
      });

      map.addEventListener("region-change-end", () => {
        setCurrentRegion(map.region);
      });
    }
  }, [map]);

  useEffect(() => {
    if (map && location?.coordinate) {
      var loc = new mapkit.Coordinate(
        Number(location.coordinate.latitude),
        Number(location.coordinate.longitude)
      );

      const region = currentRegion
        ? new mapkit.CoordinateRegion(
            loc,
            new mapkit.CoordinateSpan(
              currentRegion.span.latitudeDelta,
              currentRegion.span.longitudeDelta
            )
          )
        : new mapkit.CoordinateRegion(
            loc,
            new mapkit.CoordinateSpan(0.02, 0.02)
          );

      map.setRegionAnimated(region);

      if (currentAnnotation) {
        map.removeAnnotation(currentAnnotation);
      }

      const newAnnotation = new mapkit.MarkerAnnotation(loc);
      newAnnotation.title = location?.name || "";
      map.addAnnotation(newAnnotation);
      setCurrentAnnotation(newAnnotation);
    }
  }, [location]);

  return (
    <div ref={mapRef} style={{ width: "100%", height: "100vh" }}>
      {loader && (
        <div className={classes.loader_container}>
          <Loader />
        </div>
      )}
    </div>
  );
};
export default Map;
