import styled from "@emotion/styled";

export const AxisContainer = styled("div")({
  position: "absolute",
  height: "100%",
  width: "100%",
  gap: "20px",
  zIndex: 0,
});

export const XAxis = styled("div")({
  width: "100%",
  height: "25px",
  borderTop: "1px #aaaaaa solid",
});
