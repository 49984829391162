import { API_ANALYSIS, BACKEND_API_URL } from "services/config";
import HTTPClient from "./HTTPClient";

const env: string = process.env.REACT_APP_ENV || "development";

export const Request = new HTTPClient(
  BACKEND_API_URL[env as keyof typeof BACKEND_API_URL]
);

export const AnalyisRequest = new HTTPClient(
  process.env.REACT_APP_API_ANALYSIS_URL || API_ANALYSIS
);
