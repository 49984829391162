import { classNames } from "app/utils/classes";
import classes from "./style.module.css";
import { PricingItemProps } from "./types";

const PricingItem: React.FunctionComponent<PricingItemProps> = ({
  color,
  title,
  features,
}) => {
  return (
    <div>
      <div className={classNames([classes.pricing_item])}>
        <div className={classNames([classes.pricing_item_header, color])}>
          {title}
        </div>
        <div className={classNames([classes.pricing_item_content])}>
          {features.map((f) => {
            return (
              <div className={classes.pricing_item_content__feat}>
                <span className={classes.pricing_item_content__featValue}>
                  {f.value}
                </span>
                <span className={classes.pricing_item_content__featName}>
                  {f.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames([classes.pricing_item_action, color])}>
        {title === "Enterprise" ? "Contact Sales" : "Get Started"}
      </div>
    </div>
  );
};
export default PricingItem;
